export const user = {
  state: {
    loggedIn: false,
    userId: 0,
  },
  reducers: {
    // {
    //   loggedIn: bool,
    // }
    setLoggedIn: function(state, payload){
      return Object.assign({}, state, payload);
    }
  }
}

import React from 'react';

export default class BackOfficeElementComponent extends React.Component {
  render() {
    //console.log("boEl: ", this.props);
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                {this.props.backOfficeElement.leftTitle}
              </td>
              <td>
                {this.props.backOfficeElement.rightTitle}
              </td>
            </tr>
            {Object.keys(this.props.backOfficeElementToQuestions).includes(
              this.props.backOfficeElement.id
            ) ? Object.keys(this.props.backOfficeElementToQuestions[
              this.props.backOfficeElement.id
            ]).map(key => {
              let qId = this.props.backOfficeElementToQuestions[this.props
                .backOfficeElement.id][key];
              let question = this.props.questions.byId[qId];
              console.log(this.props.backOfficeElementToQuestions);
              console.log(this.props.backOfficeElement.id);
              console.log(key);
              return <tr>
                <td>
                { question && question.vraag ? question.vraag : '' }    
                </td>
                <td></td>
              </tr>

            })
            : null}
          </tbody>
        </table>
        <br />
      </div>
    )
  }
}

import React from 'react';

export default class QuestionElementComponent extends React.Component {
  render() {
    return (
      <div>
        Frontoffice vragen: <br />
        {Object.keys(this.props.elementGegevens).length > 0 ? Object.keys(this.props.elementGegevens)
          .map(key => {
            return <div key={"vraag" + key}>
              {this.props.elementGegevens[key].alternateQuestion ?
                this.props.elementGegevens[key].alternateQuestion
                : this.props.questions[
                  this.props.elementGegevens[key].questionId
                ].vraag}
            </div>;
          }) : "Nog geen vragen ingevuld"}
      </div>
    )
  }
}

import React from 'react';
import { connect } from 'react-redux';

import UploadPageComponent from './UploadPageComponent';

class UploadPageContainer extends React.Component {
  render() {
    return (
      <UploadPageComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let hoofdstukElementenList = state.uploadHoofdstuk.hoofdstukToIds[
    ownProps.match.params.pageId
  ];

  let elementen = [];

  if(hoofdstukElementenList !== undefined && Object.keys(hoofdstukElementenList).length > 0){
    Object.keys(hoofdstukElementenList).forEach(key => {
      let id = hoofdstukElementenList[key];
      elementen.push(state.uploadHoofdstuk.byId[id]);
    });
  }
  return {
    hoofdstukElementen: elementen,
    questions: state.question.byId,
    uploadHoofdstukElementen: state.uploadHoofdstuk,
    questions: state.question,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeUploadElement: dispatch.uploadHoofdstuk.changeUploadElement,
    //   addHoofdstuk: dispatch.hoofdstuk.addHoofdstuk,
    //   addHoofdstukElement: dispatch.hoofdstukElement.addHoofdstukElement,
      removeUploadHoofdstukElement: dispatch.uploadHoofdstuk.removeUploadHoofdstukElement,
      fixOrder: dispatch.uploadHoofdstuk.fixOrder,
      addQuestionToPage: dispatch.uploadHoofdstuk.addUploadHoofdstukElement,
    //   changeFotoElement: dispatch.fotoHoofdstukElement.changeFotoElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadPageContainer);

import React from 'react';
import { connect } from 'react-redux';

import TextManagerAddComponent from './TextManagerAddComponent';

class TextManagerAddContainer extends React.Component {
  render() {
    return (
      <TextManagerAddComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let hoofdstuk = state.hoofdstuk.byId[ownProps.match.params.hoofdstukId];
  let categories = {};
  if(hoofdstuk.isGeneric === "1" || hoofdstuk.pakketId === null){
    categories = state.textManager.categories
  } else {
    //Find appliccable categories
    Object.keys(state.textManager.categories).forEach(key => {
      let cat = state.textManager.categories[key];
      //if(cat.pakketGroepId === hoofdstuk.pakketId){
        categories[cat.id] = cat;
      //}
    })
  }
  return {
    categories: categories,
    pakketGroepen: state.pakketGroep.byId,
    saveText: state.saveText,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addText: dispatch.textManager.addText,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextManagerAddContainer);

import React from 'react';
import { connect } from 'react-redux';

import TextManagerComponent from './TextManagerComponent';

class TextManagerContainer extends React.Component {
  render() {
    return (
      <TextManagerComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  if(ownProps.match.params.textId === undefined){
    return {
      main: true,
    };
  }
  let text = state.textManager.texts[ownProps.match.params.textId];
  return {
    category: state.textManager.categories[text.catId],
    text: text,
    pakketGroepen: state.pakketGroep.byId,
    main: false,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      editTextElement: dispatch.textElement.editTextElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextManagerContainer);

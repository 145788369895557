//
export const textManager = {
  state: {
    categories: {},
    allIds: [],
    texts: {},
    catToTexts: {},
  },
  reducers: {
    // Expected Payload:
    // {
    //   id: int,
    //   pakketId: int,
    //   name: str,
    // }
    addCategory: function(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let ret =  {
        categories: Object.assign({}, state.categories, obj),
        allIds: [...state.allIds, payload.id],
        texts: state.texts,
        catToTexts: state.catToTexts,
      }
      return ret;
    },
    // Expected Payload:
    // {
    //   id: int,
    //   catId: int,
    //   naam: str,
    //   text: str,
    // }
    addText: function(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let cat = {};
      if(Object.keys(state.catToTexts).includes(payload.catId)){
        cat[payload.catId] = [...state.catToTexts[payload.catId], payload.id];
      } else{
        cat[payload.catId] = new Array(payload.id);
      }
      return {
        categories: state.categories,
        allIds: state.allIds,
        texts: Object.assign({}, state.texts, obj),
        catToTexts: Object.assign({}, state.catToTexts, cat),
      }
    }
  }
}

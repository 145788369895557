export const formulier = {
  state: {
    byId: {},
    formulierToQuestion: {},
    allIds: [],
  },
  reducers: {
    addFormulier: function(state, payload) {
      let newById = state.byId;
      let obj = {};
      obj[payload.id] = payload;
      newById = Object.assign({}, newById, obj);
      let newAllIds = [...state.allIds, payload.id];

      return {
        byId: newById,
        formulierToQuestion: state.formulierToQuestion,
        allIds: newAllIds,
      };
    },

    // Expected payload:
    // {
    //   formId: int,
    //   questionId: int,
    //   questionType: int
    // }
    addQuestionToFormulier: function(state, payload){
      let newFormToQuestion = state.formulierToQuestion;
      if(Object.keys(newFormToQuestion).includes(payload.formId)){
        newFormToQuestion[payload.formId] = [
          ...newFormToQuestion[payload.formId], {
            vraagId: payload.vraagId,
            vraagType: payload.type,
            formulierRegelId: payload.formulierRegelId,
          }
        ];
      } else {
        newFormToQuestion[payload.formId] = [{
          vraagId: payload.vraagId,
          vraagType: payload.type,
          formulierRegelId: payload.formulierRegelId,
        }]
      }
      return {
        byId: state.byId,
        allIds: state.allIds,
        formulierToQuestion: newFormToQuestion,
      };
    }
  }
}

import React from 'react';
import { connect } from 'react-redux';

import VragenElementEditComponent from './VragenElementEditComponent';

class VragenElementEditContainer extends React.Component {
  render(){
    return (
      <VragenElementEditComponent
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  let orderedData = {};
  let elementId = props.match.params.elementId;
  let elementToElementId = state.questionElement.elementIdToQuestionElementId[
    elementId
  ];
  if(typeof elementToElementId !== "undefined"){
    Object.keys(elementToElementId).forEach(order => {
      orderedData[order] = state.questionElement.byId[elementToElementId[order]];
    });
  }
  return {
    vraagElementen: orderedData,
    vragen: state.question,
    reloadGarantie: state.questionElement.elementIdToQuestionElementId,
    hoofdstuk: state.hoofdstuk.byId[props.match.params.hoofdstukId],
    triggers: state.trigger,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addQuestionElement: dispatch.questionElement.addQuestionElement,
      setOrder: dispatch.questionElement.setOrder,
      changeQuestionElement: dispatch.questionElement.changeQuestionElement,
      deleteQuestionElement: dispatch.questionElement.deleteQuestionElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VragenElementEditContainer);

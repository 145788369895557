import React from 'react';

import SideBarComponent from './SideBarComponent';
import { connect } from 'react-redux';

class SideBarContainer extends React.Component {
  render() {
    return (
      <SideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    currentUser: state.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addUser: dispatch.users.addUser
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer);

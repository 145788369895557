import React from 'react';

import GlobalSideBarContainer from '../../Generic/SideBar/GlobalSideBarContainer';
import { TemplateApiHelper } from '../../../apiHandlers/TemplateApiHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

require('./style.css');

export default class SideBarComponent extends React.Component {

  render(){
    return (
      <React.Fragment>
        <button
          className="createNewBtn"
          onClick={() => {
            TemplateApiHelper.addNewTemplate((data) => {
              this.props.actions.addTemplate({
                id: data.id,
                name: "Nieuw template",
                pakketId: null,
                showUploadPage: "0",
              });
              this.props.history.push(process.env.PUBLIC_URL + "/Templates/" + data.id);
            });
          }}
        >
        <label>
          <FontAwesomeIcon icon="plus-circle" className="new" />
            Nieuw
        </label>
        </button>
        <GlobalSideBarContainer
          type="Template"
          history={this.props.history}
        />
      </React.Fragment>
    )
  }
}

export const templateHoofdstuk = {
  state: {
    allIds: [],
    byId: {},
    templateToHoofdstuk: {},
  },
  reducers: {
    // Expected payload
    // {
    //   hoofdstukId: int,
    //   templateId: int,
    // }
    deleteTemplateHoofdstuk: function(state, payload){
      let newState = state;
      let id = payload.templateId + "-" + payload.hoofdstukId;
      delete newState.byId[id];
      newState.allIds = newState.allIds.filter(item => item !== id);
      newState.templateToHoofdstuk[payload.templateId] =
        newState.templateToHoofdstuk[payload.templateId].filter(item => {
          return item !== payload.hoofdstukId
        });
      return {
        byId: newState.byId,
        allIds: newState.allIds,
        templateToHoofdstuk: newState.templateToHoofdstuk,
      }
    },

    // Expected payload:
    // {
    //   templateId: int,
    //   hoofdstukId: int,
    //   name: string,
    //   internalName: string,
    //   order: intr,
    // }
    linkTemplateHoofdstuk: function(state, payload){
      let obj = {};
      let id = payload.templateId + "-" + payload.hoofdstukId;
      obj[id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, id];
      let newTemplateToHoofdstuk = state.templateToHoofdstuk;
      if(Object.keys(newTemplateToHoofdstuk).includes(payload.templateId)){
         let newObj = {};
         newObj[payload.templateId] = [
           ...newTemplateToHoofdstuk[payload.templateId],
           payload.hoofdstukId
         ];
         newTemplateToHoofdstuk = Object.assign({}, newTemplateToHoofdstuk,
           newObj
         );
      } else {
        newTemplateToHoofdstuk[payload.templateId] = [payload.hoofdstukId];
      }

      return {
        byId: newById,
        allIds: allIds,
        templateToHoofdstuk: newTemplateToHoofdstuk,
      }
    },

    fixOrder(state, payload){
      let newState = state;
      if(payload.length > 0){
        payload.forEach(obj => {
          let id = obj.templateId + "-" + obj.hoofdstukId;
          newState.byId[id].order = obj.order;
        });
      }
      return newState;
    }
  }
}

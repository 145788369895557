import React from 'react';

require("./style.css");

export default class GlobalSideBarComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      module: null,
      dataCollector: null,
    };
  }
  /*
   * First of all we want to import the asked contentType containers
   * We split this up to be able to dynamically load these cmponents, and
   * minimize the amount of code we had to load each time
   * The path of these components need to be:
   * ./ContentContainers/$componentName/$componentNameContainer
   * E.g., for Vraag: ./ContentContainers/Vraag/VraagContainer
   */
  componentDidMount(){
    let componentName = this.props.type;
    componentName = componentName.charAt(0).toUpperCase() +
      componentName.slice(1);
    import("./ContentContainers/" + componentName + "/" + componentName +
      "SidebarContainer").then(module => {
        this.setState({module: module.default})
      });
  }

  render() {
    const { module: Component } = this.state;
    return(
      <div id="sidebar">
        { Component !== null ?
          <Component {...this.props} /> :
          null }
      </div>
    )
  }
}

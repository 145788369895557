import { getFetch } from '../utils/fetches';

export const UserApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  apiFuncs.getUsers = function(callback){
    return getFetch(apiUrl + "Gebruiker", callback);
  };

  // {
  //   userId: int
  // }
  apiFuncs.getUserRechten = function(data, callback){
    return getFetch(apiUrl + "Gebruiker?getRechten=true&userId=" + data.userId,
    callback);
  };

  apiFuncs.createNewUser = function(callback){
    return getFetch(apiUrl + "Gebruiker?newGebruiker=true", callback, {
      method: "POST",
      body: JSON.stringify({}),
    });
  }

  // Expected data:
  // {
  //   id: int,
  //   username: str,
  //   name: str,
  //   email: str,
  //   online: bool,
  //   password: str
  // } 

  apiFuncs.changeUser = function(data, callback){
    return getFetch(apiUrl + "Gebruiker?editUser=true", callback, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  // Expected Payload:
  // {
  //   userId: int,
  //   catId: int,
  //   type: str,
  //   allow: int,
  // }
  apiFuncs.changeUserRechten = function(data, callback){
    return getFetch(apiUrl + "Gebruiker?editUserRechten=true", callback, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  // expected data: {
  //  userId: int,
  // }
  apiFuncs.getPakketGroepenForUsers = function(data, callback){
    return getFetch(apiUrl + "Gebruiker?getPakketGroepen=true&userId="
      + data.userId, callback)
  }

  // Expected data:
  // {
  //   userId: int,
  //   pakketGroepId: int,
  // }
  apiFuncs.linkPakketGroepToUser = function(data, callback){
    return getFetch(apiUrl + "Gebruiker?linkToPakketGroep=true", callback, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  // Expected data:
  // {
  //   userId: int,
  //   pakketGroepId: int,
  // }
  apiFuncs.unlinkPakketGroepToUser = function(data, callback){
    return getFetch(apiUrl + "Gebruiker?unlinkToPakketGroep=true", callback, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }

  return apiFuncs;
}());

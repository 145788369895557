import React from 'react';
import { connect } from 'react-redux';

import HoofdstukEditComponent from './HoofdstukEditComponent';

class HoofdstukEditContainer extends React.Component {
  render(){
    return (
      <HoofdstukEditComponent
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  let elements = {};
  //Check if there are questionElements for this object
  // if(Object.keys(state.questionElement.elemenIdToQuestionElements).includes(
  //   this.props.match.params.hoofdstukId
  // )){
  //
  // }

  let hoofdstukId = props.match.params.hoofdstukId;
  if(Object.keys(state.hoofdstukElement.hoofdstukToIds)
    .includes(props.match.params.hoofdstukId)){
    Object.keys(state.hoofdstukElement.hoofdstukToIds[hoofdstukId])
        .forEach(id => {
          elements[id] = state.hoofdstukElement.byId[state.hoofdstukElement
            .hoofdstukToIds[hoofdstukId][id]]
        });
    }
  return {
    elements: elements,
    questionElement: state.questionElement.byId,
    textElement: state.textElement.byId,
    hoofdstuk: state.hoofdstuk.byId[hoofdstukId],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HoofdstukEditContainer);

import React from 'react';
import { connect } from 'react-redux';

import BackOfficeElementComponent from './BackOfficeElementComponent';

class BackOfficeElementContainer extends React.Component {
  render() {
    return (
      <BackOfficeElementComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let elementId = state.hoofdstukElement.byId[ownProps.elementId].elementId;
  return {
    backOfficeElement: state.backOfficeQuestionElement.byId[elementId],
    questions: state.question,
    backOfficeElementToQuestions: state.backOfficeQuestionElement
      .elementIdToQuestionElementId
  };
}

const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeElementContainer);

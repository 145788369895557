export const pakketGroep = {
  state: {
    byId: {},
    allIds: [],
  },
  reducers: {
    addPakketGroep: function(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      return {
        byId: Object.assign({}, state.byId, obj),
        allIds: [...state.allIds, payload.id],
      };
    }
  }
}

import { getFetch } from '../utils/fetches';

export const FormulierApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/rest/";

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */
   apiFuncs.getAllFormulieren = function(callback){
     return getFetch(apiUrl + "Formulier", callback);
   }

   apiFuncs.getFormulierPakket = function(callback){
     return getFetch(apiUrl + "Formulier?getFormulierToPakket=true", callback);
   }

   apiFuncs.getQuestionsFromFormulier = function(formulierId, callback) {
      return getFetch(apiUrl + "FormulierRegel?formulierId=" + formulierId,
        callback);
    }

    apiFuncs.getTriggers = function(formulierRegelId, callback){
      return getFetch(apiUrl + "FormulierRegel?getTriggers=true&id=" +
        formulierRegelId, callback);
    }

  return apiFuncs;
}());

import React from 'react';
import { connect } from 'react-redux';

import ToolbarComponent from './ToolbarComponent';

class ToolbarContainer extends React.Component {
  render(){
    return (
      <ToolbarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, props) => {  return {
    hoofdstukData: state.hoofdstuk.byId[props.hoofdstukId],
    hoofdstukElement: state.hoofdstukElement,
    textElements: state.textElement,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    actions: {
      addHoofdstukElement: dispatch.hoofdstukElement.addHoofdstukElement,
      addTextElement: dispatch.textElement.addTextElement,
      addQuestionElement: dispatch.questionElement.addQuestionElement,
      removeHoofdstukElement: dispatch.hoofdstukElement.removeHoofdstukElement,
      fixOrder: dispatch.hoofdstukElement.fixOrder,
      addBackOfficeElement: dispatch.backOfficeQuestionElement
        .addBackOfficeElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolbarContainer)

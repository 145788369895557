import React from 'react';
import { TemplateApiHelper } from '../../../../apiHandlers/TemplateApiHelper';

require('./style.css');

export default class LeftSideComponent extends React.Component {

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  render(){
    return (
      <React.Fragment>
      <div
        key="staticDropArea"
        id="staticDropArea"
        onDrop={(e) => {
          e.target.style.border = "none";
          let dropData = JSON.parse(e.dataTransfer.getData("data"));
          let jsonData = {
            templateId: this.props.templateId,
            hoofdstukId: dropData.hoofdstukId,
            name: this.props.hoofdstukData.byId[dropData.hoofdstukId].title,
            internalName: this.props.hoofdstukData
              .byId[dropData.hoofdstukId].internalTitle,
            order: 1,
          };
          TemplateApiHelper.createHoofdstukTemplate(jsonData, response => {
            this.props.actions.linkTemplateHoofdstuk(jsonData);
            this.props.actions.fixOrder(response.newOrder);
          });

          document.querySelectorAll(".dropArea").forEach(el => {
            el.style.display = "none";
          });
        }}
        onDragOver={(e) => {
          e.preventDefault();
          document.querySelector("#staticDropArea").style.border="1px dotted black";
        }}
        onDragLeave={(e) => {
          document.querySelector("#staticDropArea").style.border="0px dotted black";
          document.querySelectorAll(".dropArea").forEach(el => {
            el.style.display = "none";
          });
        }}
      >
      </div>
      {Object.keys(this.props.hoofdstukken).map(key => {
        let hoofdstuk = this.props.hoofdstukken[key];
        return (<React.Fragment key={"fragment" + key}>
          <div
            key={"droppable" + key}
            className="droppable draggableElement"
            draggable="true"
            onDragStart={(e) => this.onDragStart(e, JSON.stringify({
              type: "hoofdstuk",
              hoofdstukId: hoofdstuk.hoofdstukId,
            }))}
            onDrop={(e) => {
              e.target.style.border = "none";
              let dropData = JSON.parse(e.dataTransfer.getData("data"));
              let jsonData = {
                templateId: this.props.templateId,
                hoofdstukId: dropData.hoofdstukId,
                name: this.props.hoofdstukData.byId[dropData.hoofdstukId].title,
                internalName: this.props.hoofdstukData
                  .byId[dropData.hoofdstukId].internalTitle,
                order: parseInt(hoofdstuk.order, 10) + 1,
              };
              TemplateApiHelper.createHoofdstukTemplate(jsonData, response => {
                this.props.actions.linkTemplateHoofdstuk(jsonData);
                this.props.actions.fixOrder(response.newOrder);
              });
              document.querySelectorAll(".dropArea").forEach(el => {
                el.style.display = "none";
              });
            }}
            onDragOver={(e) => {
              e.preventDefault();
              if(
                e.target.closest("div.droppable")
                  .nextElementSibling !== null
              ){
                e.target.closest("div.droppable")
                  .nextElementSibling.style.display = "block";
              }
            }}
            onDragLeave={(e) => {
              document.querySelectorAll(".dropArea").forEach(el => {
                el.style.display = "none";
              });
            }}
          >
            <img
              src={"/letters/h.svg"}
              alt="H"
              className="svgImg"
            />
            <span className="elementName">
              {hoofdstuk.internalName.length > 0 ? hoofdstuk.internalName :
                 hoofdstuk.name}
             </span>
             <span
             className="delButton"
              style={{
                color: 'red',
                cursor: 'pointer',
              }}
              onClick={() => {
               TemplateApiHelper.deleteTemplateHoofdstuk({
                  templateId: this.props.templateId,
                  hoofdstukId: hoofdstuk.hoofdstukId,
               },
               () => {
                 this.props.actions.deleteTemplateHoofdstuk({
                    templateId: this.props.templateId,
                    hoofdstukId: hoofdstuk.hoofdstukId
                  });
               })
             }}>
               <img src="/letters/x.svg" alt="x" className="svgImg" />
             </span>
           </div>
          <div
            key={"dropArea" + key}
            className="dropArea"
          ></div>
        </React.Fragment>
      );
      })}
      </React.Fragment>
    )
  }

}

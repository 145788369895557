import React from 'react';
import { UserApiHelper } from '../../../apiHandlers/UserApiHelper';

require('./style.css');

export default class GebruikerDetailsComponent extends React.Component {

  changeForm(key, value){
    let id = this.props.match.params.gebruikersId;
    let obj = {};
    obj.id = id;
    obj[key] = value;
    this.props.actions.changeUser(obj);
  }

  changeRechten(userId, catId, type, val){
    let allow = true;
    let jsonAllow = "1";
    if(!val){
      allow = false
      jsonAllow = "0";
    }
    let obj = {
      userId: userId,
      catId: catId,
      type: type,
      allow: allow,
    }
    let jsonObj = {
      userId: userId,
      catId: catId,
      type: type,
      allow: jsonAllow,
    }
    UserApiHelper.changeUserRechten(jsonObj, (data) => {
        this.props.actions.setRechtenToUser(obj);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div key="leftSide" className="leftSide">
        <div className="title">
          Gebruikers
        </div>
          <table>
            <tbody>
              <tr key="regel1">
                <td key="userName">
                  Gebruikersnaam:
                </td>
                <td key="userField">
                  <input
                    type="text"
                    value={this.props.currentUser.username}
                    onChange={(e) => this.changeForm("username", e.target.value)}
                  />
                </td>
              </tr>
              <tr key="regel2">
                <td key="naam">
                  Naam collega:
                </td>
                <td key="naamInput">
                  <input
                    type="text"
                    value={this.props.currentUser.name}
                    onChange={(e) => this.changeForm("name", e.target.value)}
                  />
                </td>
              </tr>
              <tr key="regel3">
                <td key="email">
                  Emailadres:
                </td>
                <td key="emailInput">
                  <input
                    type="text"
                    value={this.props.currentUser.email}
                    onChange={(e) => this.changeForm("email", e.target.value)}
                  />
                </td>
              </tr>
              <tr key="regel4">
                <td key="pass">
                  Wachtwoord:
                </td>
                <td key="passInput">
                  <input
                    type="password"
                    id="password"
                  />
                </td>
              </tr>
              <tr key="regel5">
                <td key="pass">
                  Herhaal wachtwoord:
                </td>
                <td key="passInput">
                  <input
                    type="password"
                    id="confirmPassword"
                  />
                </td>
              </tr>
              <tr key="regel6">
                <td key="offline">
                  Plaats gebruiker offline:
                </td>
                <td key="offlineInput">
                  <input
                    type="checkbox"
                    checked={this.props.currentUser.online == "1" ? false : true}
                    onChange={(e) => {
                      let online = "1";
                      if(e.target.checked){
                        online = "0";
                      }
                      this.changeForm("online", online);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="title gebruikersRechten">
            Gebruikers > Rechten
          </div>
          <table className="rechtenTable">
            <tbody>
              <tr>
                <td>
                </td>
                <td>
                  Aanmaken
                </td>
                  <td>
                  Wijzigen
                </td>
                  <td>
                  Dupliceren
                </td>
                  <td>
                  Offline
                </td>
                <td>
                  Inzien
                </td>
              </tr>
                <tr>
                  <td>
                    Gebruikers
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "1",
                          "Aanmaken",
                          e.target.checked
                        )}
                      checked={this.props.rechten["1"].Aanmaken == '1' ? true
                      : false}
                    />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "1",
                          "Wijzigen",
                          e.target.checked
                        )}
                      checked={this.props.rechten["1"].Wijzigen == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "1",
                          "Dupliceren",
                          e.target.checked
                        )}
                      checked={this.props.rechten["1"].Dupliceren == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "1",
                          "Offline",
                          e.target.checked
                        )}
                      checked={this.props.rechten["1"].Offline == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "1",
                          "Inzien",
                          e.target.checked
                        )}
                      checked={this.props.rechten["1"].Inzien == '1' ? true
                      : false}  />
                  </td>
                </tr>
                <tr>
                  <td>
                    Rapporten
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "2",
                          "Aanmaken",
                          e.target.checked
                        )}
                      checked={this.props.rechten["2"].Aanmaken == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "2",
                          "Wijzigen",
                          e.target.checked
                        )}
                      checked={this.props.rechten["2"].Wijzigen == '1' ? true
                      : false}  />
                  </td>
                    <td>
                  </td>
                    <td>
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "2",
                          "Inzien",
                          e.target.checked
                        )}
                      checked={this.props.rechten["2"].Inzien == '1' ? true
                      : false}  />
                  </td>
                </tr>
                <tr>
                  <td>
                    Templates
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "3",
                          "Aanmaken",
                          e.target.checked
                        )}
                      checked={this.props.rechten["3"].Aanmaken == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "3",
                          "Wijzigen",
                          e.target.checked
                        )}
                      checked={this.props.rechten["3"].Wijzigen == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "3",
                          "Dupliceren",
                          e.target.checked
                        )}
                      checked={this.props.rechten["3"].Dupliceren == '1' ? true
                      : false}  />
                  </td>
                    <td>
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                    Sjablonen
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "4",
                          "Aanmaken",
                          e.target.checked
                        )}
                      checked={this.props.rechten["4"].Aanmaken == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "4",
                          "Wijzigen",
                          e.target.checked
                        )}
                      checked={this.props.rechten["4"].Wijzigen == '1' ? true
                      : false}  />
                  </td>
                  <td>
                    <input type="checkbox"
                      onChange={(e) => this.changeRechten(
                          this.props.match.params.gebruikersId,
                          "4",
                          "Dupliceren",
                          e.target.checked
                        )}
                      checked={this.props.rechten["4"].Dupliceren == '1' ? true
                      : false}  />
                  </td>
                  <td>
                  </td>
                  <td>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
        <div key="rightSide" className="rightSide">
        <div className="title">
          Kies pakketgroep(en):
        </div>
          {this.props.pakketGroep.allIds.map(id => {
            return (
              <div key={"div" + id}>
                <label>
                  <input
                    type="checkbox"
                    key={"checkBox" + id}
                    checked={this.props.currentUserPakketGroepen.includes(id)}
                    onChange={(e) => {
                      let data = {
                        userId: this.props.currentUser.id,
                        pakketGroepId: id,
                      }
                      if(e.target.checked) {
                        UserApiHelper.linkPakketGroepToUser(data, () => {
                          this.props.actions.linkUserToPakketGroep(data);
                        });
                      } else {
                        UserApiHelper.unlinkPakketGroepToUser(data, () => {
                          this.props.actions.unlinkUserToPakketGroep(data);
                        });
                      }
                    }}
                  /> {this.props.pakketGroep.byId[id].naam}
                </label>
              </div>
            )
          })}
        </div>
        <div className="buttonBar">
          <button
            onClick={() => {
              let user = this.props.currentUser;
              let password = document.querySelector("#password").value;
              if(password.length > 0 && password === document.
                querySelector("#confirmPassword").value) {
                  user.password = password;
              }
              UserApiHelper.changeUser(user, () => {alert("Bedankt, uw gebruiker is opgeslagen")});
            }}
          >
            Sla op
          </button>
        </div>
      </React.Fragment>
    )
  }
}

import React from 'react';

import { HoofdstukElementApiHelper } from '../../../../apiHandlers/HoofdstukElementApiHelper';

export default class LeftSideBarComponent extends React.Component {
  render(){
    let vraag = null;
    if(this.props.selectedVraag !== null){
      vraag = this.props.vragen.byId[this.props.selectedVraag];
    }

    return(
      <div>
        {this.props.selectedVraag !== null ? <div>
          Nieuwe Vraagstelling: <br />
          <textarea
              onChange={(e) => {
                this.props.actions.changeQuestionElement({
                  id: this.props.selectedVraag,
                  alternateQuestion: e.target.value,
                });
              }}
              value={vraag.alternateQuestion}
            />
          <br /><br />
          Informatie tonen: <input
            type="checkbox"
            checked={vraag.showInfo === "1"}
            onChange={(e) => {
              let val = "0";
              if(e.target.checked){
                val = "1";
              }
              this.props.actions.changeQuestionElement({
                id: this.props.selectedVraag,
                showInfo: val,
              });
            }}
          />
          <br />
          <button
            onClick={() => {
              HoofdstukElementApiHelper.changeQuestionElement(this.props.vragen.byId[
                this.props.selectedVraag
              ], () => {});
            }}
          >
            Sla op
          </button>
        </div> : <div>Kies een vraag om wijzigingen te maken</div>}
      </div>
    );
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { HoofdstukElementApiHelper } from '../../../apiHandlers/HoofdstukElementApiHelper';

require('./style.css');

export default class TextManagerComponent extends React.Component {

  render() {
      console.log(this.props.text);
    return (
      <React.Fragment>
        <div>
          <div className="title">
            Hoofdstuk > tekst manager (ophalen)
          </div>
          {this.props.main ? <div>
            Maak een selectie rechts
          </div> :
          <React.Fragment>
            <table className="settingsTable">
              <tbody>
                <tr>
                  <td>
                    Tekst naam:
                  </td>
                  <td>
                    {this.props.text.naam}
                  </td>
                </tr>
                <tr>
                  <td>
                    Tekst categorie:
                  </td>
                  <td>
                    {this.props.category.name}
                  </td>
                </tr>
                {this.props.category.pakketGroepId !== null ?
                  <tr>
                    <td>
                      Pakketgroep:
                    </td>
                    <td>
                      {this.props.pakketGroepen[this.props.category
                        .pakketGroepId].naam}
                    </td>
                  </tr>
                : null}
              </tbody>
            </table>
            <div
              className="selectedText"
              dangerouslySetInnerHTML={{__html: this.props.text.text}}
            >
            </div>
          </React.Fragment>
        }
        </div>
        <div className="buttonBar">
          <button onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/" +
            this.props.match.params.hoofdstukId + "/edit")}
            >
              Terug
          </button>
          <button onClick={() => {
            HoofdstukElementApiHelper.editTextElement({
              id: this.props.match.params.elementId,
              content: this.props.text.text
            }, () => {
              this.props.actions.editTextElement({
                id: this.props.match.params.elementId,
                content: this.props.text.text,
              });
              this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/" +
              this.props.match.params.hoofdstukId + "/edit")
            });
          }}>
              Gebruik
          </button>
        </div>
      </React.Fragment>
    )
  }
}

export const hoofdstukElement = {
  state: {
    byId: {},
    hoofdstukToIds: {},
    allIds: [],
  },
  // {
  //   id: int,
  //   elementId: int,
  //   hoofdstukId: int,
  //   type: str,
  //   order: int,
  // }
  reducers: {
    removeHoofdstukElement: function(state, payload){
      let newById = {};
      let newHoofdstukToIds = {};
      Object.keys(state.hoofdstukToIds).forEach(key => {
        let tmpObj = {};
        Object.keys(state.hoofdstukToIds[key]).forEach(tmp => {
          if(state.hoofdstukToIds[key][tmp] !== payload.hoofdstukElementId){
            tmpObj[tmp] = state.hoofdstukToIds[key][tmp]
          }
        })
        newHoofdstukToIds[key] = tmpObj;
      });


      Object.keys(state.byId).filter(key => {
        return key !== payload.hoofdstukElementId
      }).forEach(data => {
        newById[data] = state.byId[data];
      });

      // return state;
      let newState = Object.assign({}, state, {
        byId: newById,
        hoofdstukToIds: newHoofdstukToIds,
      });
      return newState;
    },
    addHoofdstukElement: function(state, payload){
      let newById = state.byId;
      let obj = {};
      obj[payload.id] = {
        id: payload.id,
        elementId: payload.elementId,
        hoofdstukId: payload.hoofdstukId,
        type: payload.type,
        order: payload.order
      };
      newById = Object.assign({}, newById, obj);
      let newHoofdstukToIds = state.hoofdstukToIds;
      let objToAdd = {};
      objToAdd[payload.order] = payload.id;
      if(Object.keys(newHoofdstukToIds).includes(payload.hoofdstukId)){
        newHoofdstukToIds[payload.hoofdstukId] = Object.assign({},
          newHoofdstukToIds[payload.hoofdstukId], objToAdd);
      } else {
        newHoofdstukToIds[payload.hoofdstukId] = objToAdd;
      }
      return {
        byId: newById,
        hoofdstukToIds: newHoofdstukToIds,
        allIds: [...state.allIds, payload.id]
      };
    },

    // Expected payload:
    // {
    //   id: hoofdstukId,
    //   order: obj
    // }
    fixOrder(state, payload){
      let object = {};
      object[payload.id] = {};
      if(payload.order !== undefined && payload.order.length > 0){
        payload.order.forEach(obj => {
          object[payload.id][obj.order] = obj.id;
        });
      }
      let res =  Object.assign({}, state, {
        hoofdstukToIds: Object.assign({}, state.hoofdstukToId, object)
      })
      return res;
      // return newState;
    }
  }
}

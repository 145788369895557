 export const HoofdstukElementApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  apiFuncs.generateHeaders = function(){
    return new Headers({
      'Authorization': sessionStorage.getItem("jwt"),
      'Content-Type': 'application/json',
    });
  }

  apiFuncs.getHoofdstukElementen = function(callback){
    return fetch(apiUrl + "HoofdstukElement", {
      headers: this.generateHeaders(),
    })
    .then(result => result.json())
    .then(data => callback(data));
  }

  //Deze haalt enkel de data op uit de koppeltabel, NIET de daadwerkelijke elementen
  apiFuncs.getHoofdstukElementenForHoofdstuk = function(hoodstukId, callback){
    return fetch(apiUrl + "HoofdstukElement?hoofdstukId=" + hoodstukId, {
      headers: this.generateHeaders(),
    })
    .then(result => result.json())
    .then(data => callback(data));
  }

  //Deze haalt enkel de data op uit de koppeltabel, NIET de daadwerkelijke elementen
  apiFuncs.getElementenForFotoHoofdstuk = function(hoodstukId, callback){
    return fetch(apiUrl + "HoofdstukElement?fotoHoofdstukId=" + hoodstukId, {
      headers: this.generateHeaders(),
    })
    .then(result => result.json())
    .then(data => callback(data));
  }

  //Deze haalt de daadwerkelijke elementen op, NIET de koppeltabel
  apiFuncs.getElementenForHoofdstuk = function(hoodstukId, callback){
    return fetch(apiUrl + "HoofdstukElement?hoofdstukId=" + hoodstukId + "&children=true", {
      headers: this.generateHeaders(),
    })
    .then(result => result.json())
    .then(data => {
      callback(data)
    });
  }

// POST
  apiFuncs.createTextElement = function(callback){
    return fetch(apiUrl + "TextElement", {
      headers: this.generateHeaders(),
      method: "POST",
      body: JSON.stringify({
        content: "Vul hier uw text in",
      })
    })
    .then(result => result.json())
    .then(data => callback(data));
  };

  // Expected data:
  // {
  //   hoofdstukElementId: int,
  //   questionis: int,
  //   order: int,
  //   type: 'backoffice',
  // }
  apiFuncs.createBackOfficeElement = function(callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?createNewElement=true", {
      headers: this.generateHeaders(),
      method: "POST",
    })
    .then(result => result.json())
    .then(data => callback(data));
  }

  // Expected data:
  // {
  //   hoofdstukElementId: int,
  //   questionis: int,
  //   formulierRegelId: int,
  //   order: int,
  // }
  apiFuncs.createQuestionElement = function(data, callback){
    return fetch(apiUrl + "QuestionElement?createNewElement=true", {
      headers: this.generateHeaders(),
      method: "POST",
      body: JSON.stringify({
        hoofdstukElementId: data.hoofdstukElementId,
        questionId: data.questionId,
        formulierRegelId: data.formulierRegelId,
        order: data.order,
      })
    })
    .then(result => result.json())
    .then(data => callback(data));
  }
  // Expected data:
  //
  // {
  //   hoofdstukId: int,
  //   elementId: int,
  //   type: str,
  //   order: int
  // }
  apiFuncs.koppelElementToHoofdstuk = function(data, callback){
    return fetch(apiUrl + "HoofdstukElement", {
      headers: this.generateHeaders(),
      method: "POST",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // {
  //   hoofdstukElementId: int,
  //   questionElementId: int,
  //   order: int,
  // }
  apiFuncs.changeQuestionElementOrder = function(data, callback){
    return fetch(apiUrl + "QuestionElement?changeQuestionElementOrder=true", {
      headers: this.generateHeaders(),
      method: "PUT",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  apiFuncs.changeQuestionElement = function(data, callback){
    return fetch(apiUrl + "QuestionElement?changeQuestionElement=true", {
      headers: this.generateHeaders(),
      method: "PUT",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // {
  //   hoofdstukElementId: int,
  //   questionElementId: int
  // }
  apiFuncs.deleteQuestionElement = function(data, callback){
    return fetch(apiUrl + "QuestionElement?deleteQuestionElement=true", {
      headers: this.generateHeaders(),
      method: "DELETE",
      body: JSON.stringify(data)
    })    .then(response => response.json())
    .then(data => callback(data));
  };

  // Expected data:
  // {
  //   backOfficeQuestionElementId: int,
  //   id: int,
  //   order: int
  // }
  apiFuncs.createBackOfficeSubElement = function(data, callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?createNewSubElement=true", {
      headers: this.generateHeaders(),
      method: "POST",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  };

  // Expected payload:
  // {
  //   id: int,
  // }
  apiFuncs.deleteBackOfficeSubElement = function(data, callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?deleteSubelement=true", {
      headers: this.generateHeaders(),
      method: "DELETE",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  };

  // Expected payload:
  // {
  //   hoofdstukElementId: int
  // }
  apiFuncs.deleteHoofdstukElement = function(data, callback){
    return fetch(apiUrl + "HoofdstukElement?deleteHoofdstukElement=true", {
      headers: this.generateHeaders(),
      method: "DELETE",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  };

  apiFuncs.getBackOfficeSubElement = function(data, callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?subElements=true&backOfficeElementId=" + data.backOfficeElementId, {
      headers: this.generateHeaders(),
    })
    .then(response => response.json())
    .then(data => callback(data));
  };
  // Expected payload
  // {
  //   backOfficeElementId: int,
  //   id: int,
  //   order: int,
  // }
  apiFuncs.changeBackOfficeQuestionElementOrder = function(data, callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?changeBackOfficeQuestionElementOrder=true", {
      headers: this.generateHeaders(),
      method: "PUT",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected payload
  // {
  //   id: int,
  //   naam: str,
  //   leftTitle: str,
  //   rightTitle: str,
  // }
  apiFuncs.editBackOfficeQuestionElement = function(data, callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?editBackOfficeQuestionElement=true", {
      headers: this.generateHeaders(),
      method: "PUT",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected payload
  // {
  //   backOfficeElementId: int,
  //   id: int,
  //   questionId: int,
  //   alternateQuestion: string,
  // }
  apiFuncs.changeBackOfficeQuestionElement = function(data, callback){
    return fetch(apiUrl + "BackOfficeQuestionElement?editBackOfficeQuestionQuestion=true", {
      headers: this.generateHeaders(),
      method: "PUT",
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected payload:
  // {
  //   id: int,
  //   content: str,
  // }
  apiFuncs.editTextElement = function(data, callback){
    return fetch(apiUrl + "TextElement", {
      headers: this.generateHeaders(),
      method: "PUT",
      body: JSON.stringify(data),
    })
    .then(result => result.json())
    .then(data => callback(data));
  };

  return apiFuncs;
}());

import React from 'react';

export default class MainComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="title">
          Hoofdstukken
        </div>
        <div className="melding">In dit scherm kun je hoofdstukken aanmaken en wijzigen.</div>
      </React.Fragment>
    )
  }
}

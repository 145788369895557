export const AuthenticateApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */

  apiFuncs.authenticate = function(username, password, callback){
    return fetch(apiUrl + "Authenticate", {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
      .then(result => {
        if(!result.ok){
          alert("Uw inloggegevens zijn niet correct")
        } else {
          return result.json()
        }
      })
      .then(data => callback(data));
  }
  return apiFuncs;
}());

export const UploadHoofdstukApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  apiFuncs.generateHeaders = function(){
    return new Headers({
      'Authorization': sessionStorage.getItem("jwt"),
      'content-type': 'application/json',
    });
  }

  apiFuncs.getAllUploadPages = function(callback){
    return fetch(apiUrl + "UploadPage", {
      headers: this.generateHeaders(),
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => callback(data));
  }
  //
  // Expected data:
  // {
  //   templateId: int,
  // }
  apiFuncs.createNewUploadPage = function(data, callback){
    return fetch(apiUrl + "UploadPage?createNewUploadPage=true", {
      headers: this.generateHeaders(),
      method: 'POST',
      body: JSON.stringify({
        templateId: data.templateId,
      })
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // {
  //   uploadPageId: int
  //   questionId: int
  //   order: int
  // }
  apiFuncs.addQuestionToPage = function(data, callback){
    return fetch(apiUrl + "UploadPage?addQuestionToPage=true", {
      headers: this.generateHeaders(),
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected data
  // {
  //   id: int,
  // }
  apiFuncs.deleteUploadQuestion = function(data, callback){
    return fetch(apiUrl + "UploadPage?deleteUploadQuestion=true", {
      headers: this.generateHeaders(),
      method: 'DELETE',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected data
  // {
  //   id: int,
  // }
  apiFuncs.deleteUpdatePage = function(data, callback){
    return fetch(apiUrl + "UploadPage?de§leteUploadPage=true", {
      headers: this.generateHeaders(),
      method: 'DELETE',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected data
  // {
  //   id: int,
  // }
  apiFuncs.updateUploadQuestion = function(data, callback){
    return fetch(apiUrl + "UploadPage?updateUploadQuestion=true", {
      headers: this.generateHeaders(),
      method: 'PUT',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  return apiFuncs;
}());

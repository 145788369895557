import React from 'react';
import { HoofdstukElementApiHelper }
    from '../../../apiHandlers/HoofdstukElementApiHelper';
import LeftSideBarContainer from './LeftSideBar/LeftSideBarContainer';
import { Link } from 'react-router-dom';
import { default as FormulierSideBarContainer}
  from '../../../components/Hoofdstukken/HoofdstukEdit/SideBar/FormulierSideBarContainer';

require("./style.css");

export default class BackOfficeEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVraag: null
    };
  }

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  changeVal(e){
    let obj = {};
    let id = this.props.backOfficeElement.id;
    obj["id"] = id;
    obj[e.target.name] = e.target.value;
    this.props.actions.changeQuestion(obj);
  }

  render() {
    return (
      <div>
        <div className="BOmain">
          <div className="title">
            Hoofdstuk > {this.props.hoofdstuk.title} > Backoffice vragen
          </div>
          <div className="leftSideBar">
            <LeftSideBarContainer
              selectedVraag={this.state.selectedVraag}
            />
          </div>
          <div className="centerArea">
            <table className="settingsTable">
              <tbody>
                <tr>
                  <td>
                    Naam 2 kolom:
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.props.backOfficeElement.naam}
                      name="naam"
                      onChange={(e) => this.changeVal(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Titel links in header:
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.props.backOfficeElement.leftTitle}
                      name="leftTitle"
                      onChange={(e) => this.changeVal(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    Titel rechts in header:
                  </td>
                  <td>
                    <input
                      type="text"
                      value={this.props.backOfficeElement.rightTitle}
                      name="rightTitle"
                      onChange={(e) => this.changeVal(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              key={"droppableStatic"}
              className="staticDropArea"
              onDrop={(e) => {
                let lastKey;
                let dropData = JSON.parse(e.dataTransfer.getData("data"));
                let backOfficeQuestionElementId = this.props
                  .hoofdstukElement.elementId;
                let questionId = dropData.questionId;
                let order = 1;

                let preps = {
                  backOfficeQuestionElementId: backOfficeQuestionElementId,
                  questionId: questionId.vraagId,
                  order: order
                };

                let doesExist = Object.keys(this.props.vraagElementen).some(key => {
                  lastKey = key;
                  return this.props.vraagElementen[key]
                    .questionId === dropData.questionId
                });
                if(doesExist){
                  HoofdstukElementApiHelper.changeBackOfficeQuestionElementOrder({
                    backOfficeQuestionElementId: backOfficeQuestionElementId,
                    id: this.props.vraagElementen[lastKey].id,
                    order: order,
                  }, (data => {
                    let newOrder = {};
                    data.order.forEach(orderData => {
                      newOrder[orderData.order] = orderData.id;
                    });
                    this.props.actions.setOrder({
                      elementId: backOfficeQuestionElementId,
                      order: data.order
                    });
                  }))
                } else{
                  HoofdstukElementApiHelper.createBackOfficeSubElement(preps, data => {
                    let newOrderEl;
                    let newOrder = {};
                    data.order.forEach(orderData => {
                      newOrder[orderData.order] = orderData.id;
                      if(orderData.id == data.id){
                        newOrderEl = orderData.order;
                      }
                    })
                    this.props.actions.addQuestionElement({
                        id: data.id,
                        backOfficeQuestionElementId: backOfficeQuestionElementId,
                        questionId: questionId.vraagId,
                        alternateQuestion: "",
                        order: newOrderEl,
                      });
                    this.props.actions.linkQuestion(Object.assign({},
                      preps,
                      {
                        id: data.id,
                        order: newOrderEl,
                      }
                    ));
                    this.props.actions.setOrder({
                      elementId: backOfficeQuestionElementId,
                      order: data.order,
                    });
                  });
                }
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDragLeave={(e) => {
                document.querySelectorAll(".dropArea").forEach(el => {
                  el.style.display = "none";
                });
              }}
            ></div>
            {Object.keys(this.props.vraagElementen).map(order => {
              let el = this.props.vraagElementen[order];
              //console.log(this.props.vragen);
              //console.log(this.props.vraagElementen[order]);
              return (<div>
                <div
                  key={"droppable" + order}
                  className="droppable sideBarElement"
                  draggable="true"
                  onClick={() => {
                    this.setState({
                      selectedVraag: this.props.vraagElementen[order].id
                    });
                  }}
                  onDragStart={(e) => this.onDragStart(e, JSON.stringify({
                    internal: true,
                    type: "vraag",
                    questionId: this.props.vraagElementen[order].questionid,
                  }))}

                  onDrop={(e) => {
                    let lastKey;
                    let dropData = JSON.parse(e.dataTransfer.getData("data"));
                    let backOfficeQuestionElementId = this.props
                      .hoofdstukElement.elementId;
                    let questionId = dropData.questionId;
                    let newOrder = order + 1;

                    let preps = {
                      backOfficeQuestionElementId: backOfficeQuestionElementId,
                      questionId: questionId.vraagId,
                      order: newOrder
                    };

                    let doesExist = Object.keys(this.props.vraagElementen).some(key => {
                      lastKey = key;
                      return this.props.vraagElementen[key]
                        .questionId === dropData.questionId
                    });
                    if(doesExist){
                      HoofdstukElementApiHelper.changeBackOfficeQuestionElementOrder({
                        backOfficeQuestionElementId: backOfficeQuestionElementId,
                        id: this.props.vraagElementen[lastKey].id,
                        order: newOrder,
                      }, (data => {
                        let newOrder = {};
                        data.order.forEach(orderData => {
                          newOrder[orderData.order] = orderData.id;
                        });
                        this.props.actions.setOrder({
                          elementId: backOfficeQuestionElementId,
                          order: data.order
                        });
                      }));
                    } else {
                      HoofdstukElementApiHelper.createBackOfficeSubElement(preps, data => {
                        let newOrderEl;
                        let newOrder = {};
                        data.order.forEach(orderData => {
                          newOrder[orderData.order] = orderData.id;
                          if(orderData.id == data.id){
                            newOrderEl = orderData.order;
                          }
                        })
                        this.props.actions.addQuestionElement({
                          id: data.id,
                          backOfficeQuestionElementId: backOfficeQuestionElementId,
                          questionId: questionId.vraagId,
                          alternateQuestion: "",
                          order: newOrderEl,
                        });
                        this.props.actions.linkQuestion(Object.assign({},
                          preps,
                          {
                            id: data.id,
                            order: newOrderEl,
                          }
                        ));
                        this.props.actions.setOrder({
                          elementId: backOfficeQuestionElementId,
                          order: data.order,
                        });
                      });
                    }
                    document.querySelectorAll(".dropArea").forEach(el => {
                      el.style.display = "none";
                    });
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    if(
                      e.target.closest("div.droppable")
                        .nextElementSibling !== null
                    ){
                      e.target.closest("div.droppable")
                        .nextElementSibling.nextElementSibling.style.display = "block";
                    }
                  }}
                  onDragLeave={(e) => {
                    document.querySelectorAll(".dropArea").forEach(el => {
                      el.style.display = "none";
                    });
                  }}
                >
                  <img src={"/letters/v.svg"} alt="V" className="svgImg" />
                  {this.props.vragen.byId[this.props.vraagElementen[order].questionid].vraag}
                </div>
                <span
                  className="deleteButton"
                  onClick={() => {
                    HoofdstukElementApiHelper.deleteBackOfficeSubElement({
                      id:  this.props.vraagElementen[order].id,
                    }, () => {
                      this.props.actions.unlinkQuestionToElement({
                        backOfficeElementId: this.props.vraagElementen[order].backOfficeQuestionElementId,
                        order: order,
                      })
                      this.props.actions.deleteBackOfficeSubElement({
                        id: this.props.vraagElementen[order].id
                      });
                    });
                  }}
                >
                  <img src={"/letters/x.svg"} alt="V" className="svgImg" />
                </span>
                <div
                  key={"dropArea" + order}
                  className="dropArea"
                ></div>
              </div>);
            })}
          </div>
          <div className="preview">
            <div className="previewTitle">
              {this.props.backOfficeElement.naam}
            </div>
            <table>
              <thead>
                <tr>
                  <td>
                    {this.props.backOfficeElement.leftTitle}
                  </td>
                  <td>
                    {this.props.backOfficeElement.rightTitle}
                  </td>
                </tr>
              </thead>
              <tbody>
              {Object.keys(this.props.vraagElementen).length > 0 ?
                Object.keys(this.props.vraagElementen).map(key => {
                  return <tr>
                    <td>
                      {this.props.vraagElementen[key].alternateQuestion !== "" ?
                        this.props.vraagElementen[key].alternateQuestion
                        : this.props.vragen.byId[this.props.vraagElementen[key].questionid].vraag
                      }
                    </td>
                    <td>
                    </td>
                  </tr>
                })
               : null}
              </tbody>
            </table>
          </div>
          <div className="buttonBar">
            <Link to={process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.match.params.hoofdstukId
              + "/edit"}
            >
              <button>
                  Annuleer
              </button>
            </Link>
            <button onClick={() => {
              HoofdstukElementApiHelper.editBackOfficeQuestionElement({
                id: this.props.backOfficeElement.id,
                naam: this.props.backOfficeElement.naam,
                leftTitle: this.props.backOfficeElement.leftTitle,
                rightTitle: this.props.backOfficeElement.rightTitle,
              }, () => {
                this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/"
                  + this.props.match.params.hoofdstukId + "/edit")
              });
            }}>
              Bewaar 2 Kolom
            </button>
          </div>
        </div>
        <div className='sideBar'>
          <div className='sideBarHeader'>
            Backoffice Vragen 
          </div>
          <FormulierSideBarContainer match={this.props.match} />
        </div>
      </div>
    )
  }
}

export const questionElement = {
  state: {
    byId: {},
    elementIdToQuestionElementId: {},
    allIds: [],
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int,
    //   order: int,
    //   hoofdstukElementId: int,
    //   showInfo: int,
    //   questionId: int,
    // }
    addQuestionElement(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      let newElementIdToQuestionElementId = state.elementIdToQuestionElementId;
      let newObj = {};
      newObj[payload.order] = payload.id;
      if(Object.keys(newElementIdToQuestionElementId)
        .includes(payload.hoofdstukElementId)){
          newElementIdToQuestionElementId[payload.hoofdstukElementId] =
            Object.assign({},
              newElementIdToQuestionElementId[payload.hoofdstukElementId],
              newObj
            );
        } else {
          newElementIdToQuestionElementId[payload.hoofdstukElementId] = newObj;
        }
      delete payload.hoofdstukElementId;

      return {
        byId: newById,
        allIds: allIds,
        elementIdToQuestionElementId: newElementIdToQuestionElementId,
      }
    },

    // {
    //   elementId: int,
    //   order: obj
    // }
    setOrder(state, payload){
      let newState = state;
      newState.elementIdToQuestionElementId[payload.elementId] = payload.order;
      // let newById = byId[]
      let newById = state.byId;
      console.log("pl", payload);
      console.log("newById:", newById)
      Object.keys(payload.order).forEach(key => {
        newById[payload.order[key]].order = key;
      });
      newState.byId = newById;
      return Object.assign({}, newState, state);
    },
      // {
      //   id: int
      //   key: val,
      // }

    changeQuestionElement(state, payload){
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      let newById = Object.assign({}, state.byId, obj);
      return Object.assign({}, state, {
        byId: newById,
      });
    },
    // {
    //   id: int:
    // }
    deleteQuestionElement(state, payload){
      let newState = state;
      delete newState.byId[payload.id];
      newState.allIds.splice(newState.allIds
        .indexOf(payload.id), 1);
      let obj = newState.elementIdToQuestionElementId;
      Object.keys(obj).forEach(key => {
        Object.keys(obj[key]).forEach(id => {
          if(parseInt(obj[key][id], 10) === parseInt(payload.id, 10)) {
            delete obj[key][id];
          }
        });
      })
      return {
        allIds: newState.allIds,
        byId: newState.byId,
        elementIdToQuestionElementId: obj,
      };
    }
  }
}

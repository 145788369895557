import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
require("./style.css")


export default class FormulierSidebarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      closedTriggers: [],
    };
  }

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="search"
          value={this.state.query}
          onChange={(e) => this.setState({
            query: e.target.value
          })}
          placeholder="Zoeken"
        />
        {this.props.formulieren.allIds.map(key => {
          if(Object.keys(this.props.match.params).includes("hoofdstukId")){
            let hoofdstuk = this.props.hoofdstukken.byId[
              this.props.match.params.hoofdstukId];
            //Check if HS has associated pakket
            if(!Object.keys(this.props.pakketten.pakketToForm)
              .includes(hoofdstuk.pakketId)){
                return "";
              }
            //Check if current pakket is included
            if(
              hoofdstuk.pakketId === ""
              && !this.props.pakketten.pakketToForm[hoofdstuk.pakketId].includes(
                key
              )){
              return "";
            }
          }
          let show = false;
          if(this.state.query === ""
            || this.props.formulieren.byId[key].naam.includes(this.state.query)
          ){
            show = true
          }
          let val = <div
              className="headSideBarElement"
              key={"container" + key}
            >
              <img
                src={"/letters/f.svg"}
                alt="F"
                className="svgImg"
              />
              <div className="elementName">
                {this.props.formulieren.byId[key].naam}
              </div>
              {Object.keys(this.props.formulieren.formulierToQuestion)
                .includes(key) ?
                  this.props.formulieren.formulierToQuestion[key].map(questionId => {
                    if(this.props.match.url.includes("editBackoffice")
                      && questionId.vraagType == 0
                    ){
                      //return ;
                    }
                    if(this.state.query === "" || this.props.questions
                      .byId[questionId.vraagId].vraag.includes(this.state.query)
                    ){
                      show = true                     
                    }   
                    return (
                      <div
                        key={"vraagElement" + questionId}
                        className="sideBarElement"
                        draggable="true"
                        onDragStart={(e) => {
                          return this.onDragStart(e, JSON.stringify({
                            type: "vraag",
                            questionId: questionId,
                          }))
                        }}
                      >
                                            
                      {questionId.vraagType == "1" ?
                          <img
                            src={"/letters/blueV.svg"}
                            alt="V blauw"
                            className="svgImg"
                          /> :
                          <img
                            src={"/letters/v.svg"}
                            alt="V"
                            className="svgImg"
                          />
                        }
                        <span className="elementName">                        
                         { questionId.vraagId ? this.props.questions.byId[questionId.vraagId].vraag : '' }                
                        </span>
                        
                        {!this.state.closedTriggers.includes(questionId.vraagId) ?
                          <span
                            className="appendIcon"
                            onClick={(e) => {
                              this.setState({
                                closedTriggers: [
                                  ...this.state.closedTriggers,
                                  questionId.vraagId
                                ],
                              });
                              e.stopPropagation()
                            }}
                          >
                            <FontAwesomeIcon
                              icon="chevron-circle-up"
                              className="appendIcon"
                            />
                          </span> : <span
                            className="appendIcon"
                            onClick={(e) => {
                              this.setState({
                                closedTriggers: this.state.closedTriggers
                                  .filter(key => key !== questionId.vraagId),
                              });
                              e.stopPropagation()
                            }}
                          >
                            <FontAwesomeIcon
                              icon="chevron-circle-down"
                              className="appendIcon"
                            />
                          </span>
                        }
                        {questionId.vraagId && this.props.questions.byId[questionId.vraagId]
                          .uitleg.length > 0 ?
                            <img
                              className="appendIcon"
                              src="/icons/info.svg"
                              alt="t"
                              style={{
                                marginRight: "0px",
                              }}
                            />
                          : null}
                        {Object.keys(this.props.triggers.formulierRegelLink)
                          .includes(questionId.formulierRegelId) ?
                          <React.Fragment>
                              <img
                                className="appendIcon"
                                src="/icons/trigger.svg"
                                alt="t"
                              />
                              {!this.state.closedTriggers
                                .includes(questionId.vraagId) ?
                                <React.Fragment>
                                  {this.props.triggers.formulierRegelLink[
                                    questionId.formulierRegelId
                                  ].map(data => {
                                    let triggerVraag = this.props.questions.byId[
                                      this.props.triggers.byId[
                                        data
                                      ].targetVraagId
                                    ];
                                    return <div
                                      key={"vraagElement" + triggerVraag.id}
                                      className="sideBarElement"
                                    >
                                    {triggerVraag.type == "1" ?
                                        <img
                                          src={"/letters/blueV.svg"}
                                          alt="V blauw"
                                          className="svgImg"
                                        /> :
                                        <img
                                          src={"/letters/v.svg"}
                                          alt="V"
                                          className="svgImg"
                                        />
                                      }
                                      <span className="elementName">
                                        {triggerVraag.vraag}
                                      </span>
                                    </div>
                                  })}
                                </React.Fragment>
                              :null }
                          </React.Fragment>
                        : null}
                      </div>
                    )
                  })
                : null
              }
            </div>;
            if(show){
              return val;
            }
        })}
      </React.Fragment>
    )
  }
}

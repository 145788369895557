import React from 'react';
// import TemplateDetailContainer from '../TemplateDetail/TemplateDetailContainer';

import { Link } from 'react-router-dom';

export default class PreviewComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="title">
          Templates > Preview
        </div>
        <div>
          {Object.keys(this.props.hoofdstukLinks).map(key => {
            let hsLink = this.props.hoofdstukLinks[key];
            let hoofdstuk = this.props.hoofdstukken[hsLink.hoofdstukId]
            return <div>
              <h2>
                {hsLink.name}
              </h2>
              {Object.keys(
                this.props.hoofdstukElementen.hoofdstukToIds
              ).includes(hoofdstuk.id) ? Object.keys(
                this.props.hoofdstukElementen.hoofdstukToIds[hoofdstuk.id]
              ).map(key => {
                let hsElement = this.props.hoofdstukElementen.byId[
                  this.props.hoofdstukElementen.hoofdstukToIds[hoofdstuk.id][key]
                ];
                switch (hsElement.type) {
                  case 'text':
                    return <p
                    dangerouslySetInnerHTML={{
                      __html: this.props.textElements[hsElement.elementId]
                        .content
                      }} />
                  case 'backoffice':
                    let boEl = this.props.backOfficeElement.byId[
                      hsElement.elementId
                    ];
                    return <p>
                      <table>
                        <thead>
                          <tr>
                            <td>
                              {boEl.leftTitle}
                            </td>
                            <td>
                              {boEl.rightTitle}
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                        {Object.keys(this.props.backOfficeElement
                          .elementIdToQuestionElementId
                        ).includes(
                          boEl.id
                        ) ? Object.keys(this.props.backOfficeElement
                            .elementIdToQuestionElementId[boEl.id]
                          ).map(key => {
                            let question = this.props.backOfficeQuestions.byId[
                              this.props.backOfficeElement
                                .elementIdToQuestionElementId[boEl.id][key]
                            ];
                            return (
                              <tr>
                                <td>
                                  {question.alternateQuestion === "" ?
                                    this.props.questions.byId[
                                      question.questionId
                                    ].vraag:
                                  question.alternateQuestion}
                                </td>
                                <td>
                                </td>
                              </tr>
                            )
                          }) : null}
                        </tbody>
                      </table>
                    </p>
                  case 'vragen':
                    let questionElement = {};
                    if(Object.keys(
                      this.props.questionElement.elementIdToQuestionElementId
                    ).includes(hsElement.id)){
                      questionElement = this.props.questionElement
                        .elementIdToQuestionElementId[hsElement.id];
                    }
                    return (
                      <p>
                        <table>
                          <tbody>
                          {Object.keys(questionElement).length > 0
                            ? Object.keys(questionElement).map(key => {
                              let question = questionElement[key];
                              return <tr>
                                <td>
                                  {this.props.questionElement.byId[
                                    question
                                  ].alternateQuestion === "" ? this.props
                                  .questions.byId[this.props.questionElement.byId[
                                    question
                                  ].questionId].vraag
                                  : this.props.questionElement.byId[question]
                                    .alternateQuestion}
                                  </td><td></td>
                                </tr>
                            }) : null}
                          </tbody>
                        </table>
                      </p>
                    )
                  default:
                    return "";
                }
              }) : ""}
            </div>
          })}
        </div>
        <div className="buttonBar">
          <Link to={process.env.PUBLIC_URL + "/Templates/" + this.props
            .match.params.templateId}
          >
            <button>
              Terug
            </button>
          </Link>
        </div>
      </React.Fragment>
    )
  }
}

export const twoColumn = {
  state: {
    byId: {},
    allIds: [],
    rows: {},
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int,
    //   name: str,
    //   leftTitle: str,
    //   rightTitle: str,
    //   nrRows: int,
    // }
    addTwoColumn: function(state, payload) {
      let obj = {};
      obj[payload.id] = payload;

      return {
        byId: Object.assign({}, state.byId, obj),
        allIds: [...state.allIds, payload.id],
        rows: state.rows,
      };
    },
    // Expected payload:
    // {
    //   id: int,
    //   value: str,
    // }
    editTwoColumn: function(state, payload) {
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      return{
        byId: Object.assign({}, state.byId, obj),
        allIds: state.allIds,
        rows: state.rows
      }
    },
    // Expected payload:
    // {
    //   id: int,
    //   twoColumnId: int,
    //   columnOrder: int,
    //   text: str,
    // }
    addRow: function(state, payload) {
      let obj = {};
      obj[payload.columnOrder] = {
        id: payload.id,
        text: payload.text,
      };
      let rows = {};
      if(!Object.keys(state.rows).includes(payload.twoColumnId)){
        rows[payload.twoColumnId] = obj;
      } else{
        let newObj = {};
        rows[payload.twoColumnId] = Object.assign({}, state.rows[payload.twoColumnId], obj)
      }
      return {
        byId: state.byId,
        allIds: state.allIds,
        rows: Object.assign({}, state.rows, rows),
      }
    },
    // Expected payload:
    // {
    //   twoColumnId: int,
    //   rows: Array<Object>,
    // }
    setNewRows: function(state, payload){
      let obj = {};
      obj[payload.twoColumnId] = payload.rows;
      return {
        byId: state.byId,
        allIds: state.allIds,
        rows: Object.assign({}, state.rows, obj)
      }
    },
    // Expected payload:
    // {
    //   twoColumnId: int,
    //   columnOrder: int,
    //   text: str,
    // }
    editTwoColumnRow: function(state, payload) {
      let obj = {};
      obj[payload.columnOrder] = Object.assign({}, state.rows
        [payload.twoColumnId][payload.columnOrder], {
          text: payload.text,
        });
      let newObj = {};
      newObj[payload.twoColumnId] = Object.assign({}, state.rows
        [payload.twoColumnId], obj);
      let rows = Object.assign({}, state.rows, newObj);
      return {
        byId: state.byId,
        allIds: state.allIds,
        rows: rows
      }
    },
  }
}

import React from 'react';
import { connect } from 'react-redux';

import TwoColumnToolSideBarComponent from './TwoColumnToolSideBarComponent';

class TwoColumnToolSideBarContainer extends React.Component {
  render() {
    return (
      <TwoColumnToolSideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    twoColumns: state.twoColumn.byId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addTwoColumn: dispatch.twoColumn.addTwoColumn,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoColumnToolSideBarContainer);

import React from 'react';
import { connect } from 'react-redux';

import LeftSideBarComponent from './LeftSideBarComponent';

class LeftSideBarContainer extends React.Component {
  render(){
    return(
      <LeftSideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    vragen: state.backOfficeQuestion,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeQuestionElement: dispatch.backOfficeQuestion.changeQuestionElement,
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBarContainer);

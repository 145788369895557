export const trigger = {
  state: {
    byId: {},
    allIds: [],
    formulierRegelLink: {},
  },
  reducers: {
    addTrigger: function(state, payload){
      let object = {};
      object[payload.id_trigger] = {
        //id: payload.id_trigger,,
        id: payload.id_trigger_koppel,
        vraagId: payload.vraagId,
        dependecy: payload.trigger_dep,
        targetVraagId: payload.targetVraagId,
      }
      let linkObj = {};
      if(Object.keys(state.formulierRegelLink).includes(payload.formulierRegelId)){
        linkObj[payload.formulierRegelId] = [...state.formulierRegelLink[
          payload.formulierRegelId], payload.id_trigger];
      } else {
        linkObj[payload.formulierRegelId] = [payload.id_trigger];
      }
      let res = Object.assign({}, state, {
        byId: Object.assign({}, state.byId, object),
        allIds: [...state.allIds, payload.id],
        formulierRegelLink: Object.assign({}, state.formulierRegelLink, linkObj),
      });
      return res;
    }
  }
};

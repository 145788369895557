import React from 'react';
import { TwoColumnApiHelper } from '../../../apiHandlers/TwoColumnApiHelper.js';
require('./style.css');

export default class TwoColumnToolComponent extends React.Component {

  changeVal(e){
    let val = e.target.value;
    let key = e.target.name;
    let obj = {
      id: this.props.match.params.TwoColumnId
    }
    obj[key] = val;
    this.props.actions.editTwoColumn(obj);
  }

  changeRow(e, columnOrder){
    let val = e.target.value;
    let obj = {
      twoColumnId: this.props.match.params.TwoColumnId,
      columnOrder: columnOrder,
      text: val
    };
    this.props.actions.editTwoColumnRow(obj);
  }

  changeNrRows(e){
    let val = e.target.value;
    console.log(this.props);
    TwoColumnApiHelper.setNumberOfRows({
      id: this.props.match.params.TwoColumnId,
      nrRows: val,
      rows: this.props.rows
    }, data => {
      this.props.actions.setNewRows({
        twoColumnId: this.props.match.params.TwoColumnId,
        rows: data
      });
      this.props.actions.editTwoColumn({
        id: this.props.match.params.TwoColumnId,
        nrRows: val
      })
    }
    );
  }

  renderRowSelection(){
    let ret = [];
    for (let i = 0; i <= 20; i++) {
      ret.push(<option key={i} value={i}>{i}</option>);
    }
    return ret;
  }

  render() {
    return (
      <React.Fragment>
        <div className="title">
          Hoofdstuk > 2 koloms generiek
        </div>
        {this.props.main ?
          <div>
            Maak een selectie rechts
          </div> :
          <React.Fragment>
            <div>
              <table className="leftTable">
                <tbody>
                  <tr>
                    <td>
                      Naam kolom
                    </td>
                    <td>
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => this.changeVal(e)}
                        value={this.props.twoColumnTool.name}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Titel links in header
                    </td>
                    <td>
                      <input
                        type="text"
                        name="leftTitle"
                        onChange={(e) => this.changeVal(e)}
                        value={this.props.twoColumnTool.leftTitle}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Titel rechts in header
                    </td>
                    <td>
                      <input
                        type="text"
                        name="rightTitle"
                        onChange={(e) => this.changeVal(e)}
                        value={this.props.twoColumnTool.rightTitle}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Aantal rijen
                    </td>
                    <td>
                    <select
                      value={this.props.twoColumnTool.nrRows}
                      onChange={(e) => this.changeNrRows(e)}
                    >
                      {this.renderRowSelection()}
                    </select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="rightTable">
                <tbody>
                {this.props.rows !== undefined
                  && Object.keys(this.props.rows).length > 0 ?
                  <React.Fragment>
                    {Object.keys(this.props.rows).map(key => {
                      let row = this.props.rows[key];
                      return (<tr>
                          <td>Inhoud rij {key}</td>
                          <td>
                            <input type="text"
                              onChange={(e) => this.changeRow(e, key)}
                              value={this.props.rows[key].text}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </React.Fragment> : null}
                </tbody>
              </table>
            </div>
          <div style={{clear: "both"}}></div>
          <div className="preview">
            <div className="previewTitle">
              {this.props.twoColumnTool.name}
            </div>
            <table>
              <thead>
                <tr>
                  <td>
                    {this.props.twoColumnTool.leftTitle}
                  </td>
                  <td>
                    {this.props.twoColumnTool.rightTitle}
                  </td>
                </tr>
              </thead>
              <tbody>
              {this.props.rows !== undefined
                && Object.keys(this.props.rows).length > 0 ?
                <React.Fragment>
                  {Object.keys(this.props.rows).map(key => {
                    let row = this.props.rows[key];
                    return (<tr>
                      <td className="leftRow">
                        {row.text}
                      </td>
                        <td className="rightRow">
                      </td>
                    </tr>);
                  })}
                </React.Fragment> : null}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      }
        <div className="buttonBar">
          <button onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/" +
            this.props.match.params.hoofdstukId + "/edit")}
            >
              Terug
          </button>
          <button
            onClick={() => {
              let obj = {
                twoColumn: this.props.twoColumnTool,
                rows: this.props.rows,
              }
              TwoColumnApiHelper.saveTwoColumnTool(obj, () => {
                TwoColumnApiHelper.addTwoColumnToElement({
                  twoColumnId: this.props.twoColumnTool.id,
                  elementId: this.props.match.params.elementId,
                }, (data) => {
                  this.props.actions.editTextElement({
                    id: this.props.match.params.elementId,
                    content: data.newText,
                  })
                  this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/"
                    + this.props.match.params.hoofdstukId + "/edit")
                })
              });
            }}
          >
            Voeg toe aan element
          </button>
          <button
            onClick={() => {
              let obj = {
                twoColumn: this.props.twoColumnTool,
                rows: this.props.rows,
              }
              TwoColumnApiHelper.saveTwoColumnTool(obj, () => console.log("saved"));
            }}
          >
            Sla wijzigingen op
          </button>
        </div>
      </React.Fragment>
    )
  }
}

import { getFetch } from '../utils/fetches';

export const PakketGroepApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/rest/";

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */
    apiFuncs.getAllPakketGroepen = function(callback){
      return getFetch(apiUrl + "PakketGroep", callback);
    }

  return apiFuncs;
}());

import React from 'react';
import { HoofdstukElementApiHelper } from '../../../../apiHandlers/HoofdstukElementApiHelper.js';

export default class ToolbarComponent extends React.Component {
  render(){
    return (
      <div>
        <div
          className="toolbarElement"
          onClick={() => {
            HoofdstukElementApiHelper.createTextElement(data => {
              HoofdstukElementApiHelper.koppelElementToHoofdstuk({
                  hoofdstukId: this.props.hoofdstukId,
                  elementId: data.id,
                  type: "text",
                  order: parseInt(this.props.order, 10) + 1
              }, koppelData => {
                this.props.actions.addTextElement({
                  id: data.id,
                  content: "<p>Vul hier uw text in</p>"
                });
                this.props.actions.addHoofdstukElement({
                  id: koppelData.id,
                  elementId: data.id,
                  hoofdstukId: this.props.hoofdstukId,
                  type: "text",
                  order: parseInt(this.props.order, 10) + 1,
                });
                this.props.actions.fixOrder({
                  id: this.props.hoofdstukId,
                  order: koppelData.order
                });
              })
            });
          }}
        >
          <img
            src={"/icons/text.svg"}
            alt="Text"
            title="Text"
          />
        </div>
        {this.props.hoofdstukData.isGeneric != '1' ?
          <div
            className="toolbarElement"
            onClick={() => {
              HoofdstukElementApiHelper.koppelElementToHoofdstuk({
                hoofdstukId: this.props.hoofdstukId,
                elementId: "",
                type: "vragen",
                order: parseInt(this.props.order, 10) + 1
              }, koppelData => {
                this.props.actions.addHoofdstukElement({
                  id: koppelData.id,
                  elementId: "",
                  hoofdstukId: this.props.hoofdstukId,
                  type: "vragen",
                  order: parseInt(this.props.order, 10) + 1,
                });
              })
            }}
          >
            <img
              src={"/icons/question.svg"}
              alt="Vragen"
              title="Vragen"
            />

          </div>
        : null}
        <div
          className="toolbarElement"
          onClick={() => {
            HoofdstukElementApiHelper.createBackOfficeElement(data => {
              HoofdstukElementApiHelper.koppelElementToHoofdstuk({
                hoofdstukId: this.props.hoofdstukId,
                elementId: data.id,
                type: "backoffice",
                order: parseInt(this.props.order, 10) + 1
              }, koppelData => {
                this.props.actions.addBackOfficeElement({
                  id: data.id,
                  leftTitle: "Left title",
                  rightTitle: "Right title",
                });
                this.props.actions.addHoofdstukElement({
                  id: koppelData.id,
                  elementId: data.id,
                  hoofdstukId: this.props.hoofdstukId,
                  type: "backoffice",
                  order: parseInt(this.props.order, 10) + 1,
                });
                this.props.actions.fixOrder({
                  id: this.props.hoofdstukId,
                  order: koppelData.order
                });
              })
            })
          }}
        >
          <img
            src={"/icons/bo2kolom.svg"}
            alt="BO vragen"
            title="Backoffice vragen"
          />
        </div>
        <div
          className="toolbarElement"
          onClick={(e) => {
            let order = parseInt(this.props.order, 10);
            order += 5;
            let hoofdstukElementId = this.props.hoofdstukElement.hoofdstukToIds[
              this.props.hoofdstukId
            ][order];
            if(hoofdstukElementId !== undefined){
              let hoofdstukElement = this.props.hoofdstukElement.byId[hoofdstukElementId];
              HoofdstukElementApiHelper.deleteHoofdstukElement({
                hoofdstukElementId: hoofdstukElementId
              }, data => {
                this.props.actions.removeHoofdstukElement({
                  hoofdstukElementId: hoofdstukElementId
                });
                this.props.actions.fixOrder({
                  id: this.props.hoofdstukId,
                  order: data.order
                });
              })
            }
          }}
        >
          <img
            src="/icons/delete.svg"
            alt="verwijderen"
            title="Verwijderen"
          />
        </div>
        <div style={{clear: 'both'}}></div>
      </div>
    )
  }
}

import React from 'react';
import GlobalSideBarComponent from './GlobalSideBarComponent';
import { connect } from 'react-redux';

class GlobalSideBarContainer extends React.Component {
  render() {
    return (
      <GlobalSideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSideBarContainer)

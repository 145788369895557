import React from 'react';
import { HoofdstukElementApiHelper }
    from '../../../apiHandlers/HoofdstukElementApiHelper';
import LeftSideBarContainer from './LeftSideBar/LeftSideBarContainer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

require("./style.css")

export default class VragenElementEditComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVraag: null,
      closedTriggers: [],
    };
  }

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  render() {
    return (
      <div>
        <div className="title">
          Hoofdstuk > {this.props.hoofdstuk.title} > Vragen
        </div>
        <div className="leftSideBar">
          <LeftSideBarContainer
            selectedVraag={this.state.selectedVraag}
          />
        </div>
        <div className="centerArea">
          <div
            key={"droppableStatic"}
            className="staticDropArea"
            onDrop={(e) => {
              let lastKey;
              let dropData = JSON.parse(e.dataTransfer.getData("data"));
              let jsonData = {
                hoofdstukElementId: this.props.match.params.elementId,
                questionId: dropData.questionId.vraagId,
                formulierRegelId: dropData.questionId.formulierRegelId,
                order: 1,
              };
              console.log("this.props.vraagElementen", this.props.vraagElementen)
              console.log("dropData", dropData)
              let doesExist = Object.keys(this.props.vraagElementen).some(key => {
                lastKey = key;
                return this.props.vraagElementen[key]
                  .questionId === dropData.questionId
              });
              if(doesExist){
                let vraagEl = this.props.vraagElementen[lastKey];
                let data = {
                  hoofdstukElementId: this.props.match.params.elementId,
                  questionElementId: vraagEl.id,
                  order: 1,
                };
                HoofdstukElementApiHelper.changeQuestionElementOrder(data,
                  response => {
                    let newOrder = {};
                    response.order.forEach(data => {
                      newOrder[data.order] = data.questionElementId;
                    });
                    this.props.actions.setOrder({
                      elementId: this.props.match.params.elementId,
                      order: newOrder
                    });
                  }
                )
              } else {
                HoofdstukElementApiHelper.createQuestionElement(jsonData, response => {
                  let newId = response.questionElementId;
                  let newElementData = {
                    id: newId,
                    order: 1,
                    hoofdstukElementId: this.props.match.params.elementId,
                    showInfo: 0,
                    questionId: dropData.questionId.vraagId,
                    formulierRegelId: dropData.questionId.formulierRegelId,
                  };
                  this.props.actions.addQuestionElement(newElementData);
                  let newOrder = {};
                  response.order.forEach(data => {
                    newOrder[data.order] = data.questionElementId;
                  });
                  this.props.actions.setOrder({
                    elementId: this.props.match.params.elementId,
                    order: newOrder
                  });
                });
                document.querySelectorAll(".dropArea").forEach(el => {
                  el.style.display = "none";
                });
              }
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragLeave={(e) => {
              document.querySelectorAll(".dropArea").forEach(el => {
                el.style.display = "none";
              });
            }}
          ></div>
          {Object.keys(this.props.vraagElementen).map(order => {
            return (<div>
              <div
                key={"droppable" + order}
                className="droppable sideBarElement"
                draggable="true"
                onClick={() => {
                  this.setState({
                    selectedVraag: this.props.vraagElementen[order].id
                  })
                }}
                onDragStart={(e) => this.onDragStart(e, JSON.stringify({
                  internal: true,
                  type: "vraag",
                  questionId: this.props.vraagElementen[order].questionId,
                }))}

                onDrop={(e) => {
                  e.target.style.border = "none";
                  let dropData = JSON.parse(e.dataTransfer.getData("data"));
                  let lastKey;
                  let doesExist = Object.keys(this.props.vraagElementen).some(key => {
                    lastKey = key;
                    return this.props.vraagElementen[key]
                      .questionId === dropData.questionId
                  });
                  if(doesExist){
                    let vraagEl = this.props.vraagElementen[lastKey];
                    let data = {
                      hoofdstukElementId: this.props.match.params.elementId,
                      questionElementId: vraagEl.id,
                      order: parseInt(order, 10) + 1,
                    };
                    HoofdstukElementApiHelper.changeQuestionElementOrder(data,
                      response => {
                        let newOrder = {};
                        response.order.forEach(data => {
                          newOrder[data.order] = data.questionElementId;
                        });
                        this.props.actions.setOrder({
                          elementId: this.props.match.params.elementId,
                          order: newOrder
                        });
                      }
                    )
                  } else {
                    let jsonData = {
                      hoofdstukElementId: this.props.match.params.elementId,
                      questionId: dropData.questionId.vraagId,
                      order: parseInt(order, 10) + 1,
                      formulierRegelId: dropData.questionId.formulierRegelId
                    };
                    HoofdstukElementApiHelper.createQuestionElement(jsonData, response => {
                      let newId = response.questionElementId;
                      let newElementData = {
                        id: newId,
                        order: parseInt(order, 10) + 1, 
                        hoofdstukElementId: this.props.match.params.elementId,
                        showInfo: 0,
                        questionId: dropData.questionId.vraagId,
                        formulierRegelId: dropData.questionId.formulierRegelId,
                      };
                      this.props.actions.addQuestionElement(newElementData);
                      let newOrder = {};
                      response.order.forEach(data => {
                        newOrder[data.order] = data.questionElementId;
                      });
                      this.props.actions.setOrder({
                        elementId: this.props.match.params.elementId,
                        order: newOrder
                      });
                    });
                  }
                  document.querySelectorAll(".dropArea").forEach(el => {
                    el.style.display = "none";
                  });
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  if(
                    e.target.closest("div.droppable")
                      .nextElementSibling !== null
                  ){
                    e.target.closest("div.droppable")
                      .nextElementSibling.style.display = "block";
                  }
                }}
                onDragLeave={(e) => {
                  document.querySelectorAll(".dropArea").forEach(el => {
                    el.style.display = "none";
                  });
                }}
              >
                <img
                  src={"/letters/v.svg"}
                  alt="V"
                  className="svgImg"
                />
                <span className="elementName">
                  {this.props.vragen.byId[this.props.vraagElementen[order]
                    .questionId].vraag}
                </span>
                <span
                  className="deleteButton"
                  style={{float: 'right'}}
                  onClick={() => {
                    HoofdstukElementApiHelper.deleteQuestionElement({
                      hoofdstukElementId: this.props.match.params.elementId,
                      questionElementId: this.props.vraagElementen[order].id,
                    }, () => {
                      this.setState({
                        selectedVraag: null
                      })
                      this.props.actions.deleteQuestionElement({
                        id: this.props.vraagElementen[order].id
                      })
                    });
                  }}
                >
                  <img
                    src={"/letters/x.svg"}
                    alt="x"
                    className="svgImg"
                  />
                </span>
                {!this.state.closedTriggers.includes(order) ?
                  <span
                    className="appendIcon"
                    onClick={(e) => {
                      this.setState({
                        closedTriggers: [...this.state.closedTriggers, order],
                      });
                      e.stopPropagation()
                    }}
                  >
                    <FontAwesomeIcon
                      icon="chevron-circle-up"
                      className="appendIcon"
                    />
                  </span> : <span
                      className="appendIcon"
                      onClick={(e) => {
                        this.setState({
                          closedTriggers: this.state.closedTriggers
                            .filter(key => key !== order),
                        });
                        e.stopPropagation()
                      }}
                    >
                      <FontAwesomeIcon
                        icon="chevron-circle-down"
                        className="appendIcon"
                      />
                    </span>}
                {this.props.vragen.byId[this.props.vraagElementen[order]
                  .questionId].uitleg.length > 0 ?
                    <img
                      className="appendIcon"
                      src="/icons/info.svg"
                      alt="t"
                      style={{
                        marginRight: "0px",
                      }}
                    />
                  : null}
                {Object.keys(this.props.triggers.formulierRegelLink).includes(
                  this.props.vraagElementen[order].formulierRegelId
                ) ?
                    <span>
                      <img
                        className="appendIcon"
                        src="/icons/trigger.svg"
                        alt="t"
                      />
                    </span>
                  : null}
                  {!this.state.closedTriggers.includes(order) &&
                    Object.keys(this.props.triggers.formulierRegelLink)
                    .includes(this.props.vraagElementen[order].formulierRegelId) ?
                    <React.Fragment>
                      {this.props.triggers.formulierRegelLink[
                        this.props.vraagElementen[order].formulierRegelId
                      ].map(triggeredVraag => {
                        let triggeredVraagData = this.props.vragen.byId[
                          this.props.triggers.byId[
                            triggeredVraag
                          ].targetVraagId
                        ];
                        return <React.Fragment>
                          <div
                            key={"droppable" + order}
                            className="droppable sideBarElement"
                          >
                            <img
                              src={"/letters/v.svg"}
                              alt="V"
                              className="svgImg"
                            />
                            <span className="elementName">
                              {triggeredVraagData.vraag}
                            </span>
                          </div>
                      </React.Fragment>
                    })}
                  </React.Fragment>
                  : null}
              </div>
              <div
                key={"dropArea" + order}
                className="dropArea"
              ></div>
            </div>);
          })}
        </div>
        <div className="buttonBar">
          <Link
            to={process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.match.params.hoofdstukId + "/edit"}
            key="returnUrl"
          >
            <button>
              Terug
            </button>
          </Link>
        </div>
      </div>
    )
  }
}

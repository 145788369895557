import React from 'react';

import { Link } from 'react-router-dom';
import { UserApiHelper } from '../../../apiHandlers/UserApiHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

require("./style.css");

export default class SideBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  render(){
    return (
      <div>
      <button
        onClick={() => {
          UserApiHelper.createNewUser((data) => {
            this.props.actions.addUser({
              id: data.id,
              username: 'username',
              name: 'Nieuwe Gebruiker',
              email: 'info@adviesenkies.nl',
              online: '0',
            });
            this.props.history.push(process.env.PUBLIC_URL + "/Gebruikers/" + data.id);
          });
        }}
      >
        <label>
          <FontAwesomeIcon icon="plus-circle" className="new" />
            Nieuw
        </label>
      </button>
      <input
        type="search"
        value={this.state.query}
        onChange={(e) => this.setState({
          query: e.target.value
        })}
        placeholder="Zoeken"
      />
        {this.props.users.allIds.map(id => {
          let user = this.props.users.byId[id];
          if(this.state.query === "" || user.name.includes(this.state.query)
          ){
            return <Link
              to={process.env.PUBLIC_URL + "/Gebruikers/" + id}
              className='sideBarElement'
              key={id}
            >
              <img src={"/letters/g.svg"} alt="G" className="svgImg" />
              <span
                className={this.props.currentUser.userId == id ?
                  "elementName currentUser"
                  : "elementName"
                }
              >
                {user.name} {this.props.currentUser.userId == id ?
                  "(Huidige gebruiker)"
                  : null}
              </span>
              <br />
            </Link>
          }
        })}
        <br /><br />
      </div>
    )
  }
}

import React from 'react';
import ReactDOM from 'react-dom';
import CKEditor from "react-ckeditor-component";
import { TextManagerApiHelper } from '../../../apiHandlers/TextManagerApiHelper';
require('./style.css');

export default class TextManagerAddComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      cat: null,
      naam: null,
      groep: null,
      text: props.saveText.text
    }
  }

  changeVal(e){
    let obj = {};
    obj[e.target.name] = e.target.value;
    this.setState(obj);
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="title">
            Hoofdstuk > tekst manager (bewaren)
          </div>
          <table className="settingsTable">
            <tbody>
              <tr>
                <td>
                  Tekst naam:
                </td>
                <td>
                  <input
                    type="text"
                    name="naam"
                    value={this.state.naam}
                    onChange={(e) => this.changeVal(e)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Tekst categorie:
                </td>
                <td>
                  <select name="cat" onChange={(e) => this.changeVal(e)}>
                    <option value={null}>
                      Maak een selectie
                    </option>
                    {Object.keys(this.props.categories).map(value => {
                      let cat = this.props.categories[value];
                      return <option value={cat.id}>
                        {cat.name}
                      </option>
                    })}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  Pakketgroep:
                </td>
                <td>
                  <select name="groep" onChange={(e) => this.changeVal(e)}>
                    <option value={null}>
                      Maak een selectie
                    </option>
                    {Object.keys(this.props.pakketGroepen).map(value => {
                      let groep = this.props.pakketGroepen[value];
                      return <option value={groep.id}>
                        {groep.naam}
                      </option>
                    })}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <CKEditor
          activeClass="p10"
          content={this.state.text}
          events={{
            "change": (e) => {
              this.setState({
                text: e.editor.getData(),
              });
            }
          }}
          scriptUrl="/ckeditor/ckeditor.js"
         />
        <div className="buttonBar">
          <button
            onClick={() => this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/" +
            this.props.match.params.hoofdstukId + "/edit")}
          >
            Terug
          </button>
          <button
            disabled={this.state.cat !== null
              && this.state.naam !== null
              && this.state.groep !== null ? null : "disabled"}
            onClick={() => {
              TextManagerApiHelper.createText({
                catId: this.state.cat,
                naam: this.state.naam,
                text: this.state.text,
              }, (data) => {
                this.props.actions.addText({
                    id: data.id,
                    catId: this.state.cat,
                    naam: this.state.naam,
                    text: this.state.text,
                })
                this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.match
                .params.hoofdstukId + "/edit");

              });
            }}
          >
            Bewaar
          </button>
        </div>
      </React.Fragment>
    )
  }
}

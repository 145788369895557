import React from 'react';
import FormulierSideBarComponent from './FormulierSideBarComponent';
import { connect } from 'react-redux';

class FormulierSideBarContainer extends React.Component {
  render() {
    return <FormulierSideBarComponent
      {...this.props}
    />;
  }
}

const mapStateToProps = (state) => {
//    console.log(state.question);
//    console.log(state.formulier);
  return {
    formulieren: state.formulier,
    questions: state.question,
    hoofdstukken: state.hoofdstuk,
    pakketten: state.pakket,
    triggers: state.trigger,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormulierSideBarContainer)

//
export const template = {
  state: {
    byId: {},
    allIds: [],
    templateToUpload: {},
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int
    //   name: string,
    //   pakketId: int,
    //   showUploadPage: int
    // }
    addTemplate(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      return {
        byId: newById,
        allIds: allIds,
        templateToUpload: state.templateToUpload,
      }
    },
    // {
    //   id: int,
    //   *property*: *value*,
    // }
    changeTemplate(state, payload){
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      let newById = Object.assign({}, state.byId, obj);
      return {
        byId: newById,
        allIds: state.allIds,
        templateToUpload: state.templateToUpload,
      }
    },
      // Expected payload:
      // {
      //   templateId: int,
      //   uploadPageId: int,
      // }
      addUploadPage(state, payload){
        let obj = {};
        obj[payload.templateId] = payload.uploadPageId;
        return {
          byId: state.byId,
          allIds: state.allIds,
          templateToUpload: Object.assign({}, state.templateToUpload, obj),
        }
      },
  }
}

import React from 'react';
import { connect } from 'react-redux';

import LeftSideComponent from './LeftSideComponent';

class LeftSideContainer extends React.Component {
  render() {
    return (
      <LeftSideComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let orderedData = {};
  let templateToHoofdstuk = state.templateHoofdstuk
    .templateToHoofdstuk[ownProps.templateId];
    if(typeof templateToHoofdstuk !== 'undefined' && templateToHoofdstuk.length > 0){
      templateToHoofdstuk.forEach(id => {
        let data = state.templateHoofdstuk.byId[ownProps.templateId + "-" + id];
        orderedData[data.order] = data
      })
    }
  return {
    hoofdstukken: orderedData,
    hoofdstukData: state.hoofdstuk,
    templateHoofdstuk: state.templateHoofdstuk,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      linkTemplateHoofdstuk: dispatch.templateHoofdstuk.linkTemplateHoofdstuk,
      deleteTemplateHoofdstuk: dispatch.templateHoofdstuk.deleteTemplateHoofdstuk,
      fixOrder: dispatch.templateHoofdstuk.fixOrder,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideContainer)

export const hoofdstuk = {
  state: {
    byId: {},
    allIds: []
  },
  reducers: {
    addHoofdstuk: function(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      return {
        byId: newById,
        allIds: allIds,
      }
    },
    // {
    //   id: int,
    //   *property*: *value*,
    // }
    changeHoofdstuk(state, payload){
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      let newById = Object.assign({}, state.byId, obj);
      return {
        byId: newById,
        allIds: state.allIds,
      }
    }
  }
}

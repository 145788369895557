import React from 'react';
import { connect } from 'react-redux';

import GebruikerDetailsComponent from './GebruikerDetailsComponent';

class GebruikerDetailsContainer extends React.Component {
  render(){
    return (
      <GebruikerDetailsComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  let selectedUser = props.match.params.gebruikersId;
  let currentUserPakketGroepen = state.users.userToPakketGroep[selectedUser]
  if(currentUserPakketGroepen === undefined){
    currentUserPakketGroepen = [];
  }
  return {
    currentUser: state.users.byId[selectedUser],
    currentUserPakketGroepen: currentUserPakketGroepen,
    pakketGroep: state.pakketGroep,
    rechten: state.users.userToRechten[selectedUser],
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeUser: dispatch.users.changeUser,
      linkUserToPakketGroep: dispatch.users.linkUserToPakketGroep,
      unlinkUserToPakketGroep: dispatch.users.unlinkUserToPakketGroep,
      setRechtenToUser: dispatch.users.setRechtenToUser,
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(GebruikerDetailsContainer);

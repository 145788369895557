import React from 'react';
import { Link } from 'react-router-dom';
import { HoofdstukApiHelper } from '../../../apiHandlers/HoofdstukApiHelper.js';
import { FotoHoofdstukApiHelper } from '../../../apiHandlers/FotoHoofdstukApiHelper.js';

require("./style.css")

export default class HoofdstukDetailComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedVraag: "0",
    };
  }

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  changeForm(e){
    var type = e.target.name;
    var value = e.target.value;
    if(e.target.type === "checkbox"){
      if(e.target.checked){
        value = "1";
      } else {
        value = "0";
      }
    }
    let obj = {
      id: this.props.hoofdstuk.id,
    };
    obj[type] = value;
    if(this.props.hoofdstuk.type === "foto" && type === "title"){
      obj["internalTitle"] = value;
    }
    this.props.actions.changeHoofdstuk(obj);
  }

  dropTrigger(questionId, order){
    let lastKey;
    let existingElements = this.props.fotoHoofdstukElementen
      .hoofdstukToIds[this.props.hoofdstuk.id];
    let newQuestion = true;
    let oldOrder = 0;
    if(existingElements !== undefined &&
      Object.keys(existingElements).length > 0
    ){
      Object.keys(existingElements).forEach(key => {
        if(
          this.props.fotoHoofdstukElementen.byId[
            existingElements[key]
          ].questionId === questionId
        ){
          oldOrder = key;
          newQuestion = false;
        }
      })
    }

    if(newQuestion) {
      FotoHoofdstukApiHelper.addFotoToHoofdstuk({
        hoofdstukId: this.props.hoofdstuk.id,
        questionId: questionId,
        text: "",
      }, (data) => {
        this.props.actions.addFotoHoofdstukElement({
          id: data.id,
          hoofdstukId: this.props.hoofdstuk.id,
          questionId: questionId,
          text: "",
          order: order
        });
        this.props.actions.fixFotoOrders({
          id: this.props.hoofdstuk.id,
          order: data.order
        });
      });
    } else {
      let element = this.props.fotoHoofdstukElementen.byId[
        existingElements[oldOrder]
      ];
      FotoHoofdstukApiHelper.changeHoofdstukElement({
        hoofdstukElementId: element.id,
        questionId: element.questionId,
        text: element.text,
        order: order,
      }, (data) => {
        this.props.actions.fixFotoOrders({
          id: this.props.hoofdstuk.id,
          order: data.order
        });
      });
    }
  }

  render() {    
    return (
      <React.Fragment>
        <div className="title">
          Hoofdstukken > {this.props.hoofdstuk.isGeneric === "1" ? "Generiek" :
            this.props.hoofdstuk.type == "foto" ? "Foto" : "Specifiek"}
        </div>
        {this.props.hoofdstuk.type === "foto" ? <React.Fragment>
          <table className="settingsTable">
            <tbody>
              <tr>
                <td>
                  Titel (inhoudsopgave)
                </td>
                <td>
                  <input
                    type="text"
                    name="title"
                    value={this.props.hoofdstuk.title}
                    onChange={(e) => this.changeForm(e)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Groep:
                </td>
                <td>
                {console.log("pakketten: ", this.props.linkedPakketten)}
                  {this.props.linkedPakketten !== undefined ?
                    this.props.linkedPakketten[0].pakket
                  : null}
                </td>
              </tr>
              <tr>
                <td>
                  Pakket:
                </td>
                <td>
                  {this.props.linkedPakketten !== undefined ?
                    this.props.linkedPakketten[0].template
                  : null }
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div style={{
            width: "20%",
            borderRight: "1px solid black",
            height: "80%",
            display: "inline-block",
            float: "left",
            verticalAlign: "top",
          }}>
          {this.state.selectedVraag !== "0" ?
            <React.Fragment>
              Text voor naast de foto: <br />
              <textarea id='fotoText'>
                {this.props.fotoHoofdstukElementen.byId[
                  this.state.selectedVraag
                ].text}
              </textarea>
              <br />
              <button onClick={() => {
                let element = this.props.fotoHoofdstukElementen.byId[
                    this.state.selectedVraag
                  ];
                let text = document.querySelector("#fotoText").value;
                FotoHoofdstukApiHelper.changeHoofdstukElement({
                  hoofdstukElementId: element.id,
                  questionId: element.questionId,
                  text: text,
                  order: element.order,
                }, (data) => {
                  this.setState({
                    selectedVraag: "0"
                  });
                  this.props.actions.changeFotoElement({
                    id: element.id,
                    text: text
                  })
                });
              }}>
                Sla op
              </button>
            </React.Fragment> :
            <React.Fragment>
              Details
            </React.Fragment>
          }
          </div>
          <div style={{
            width: "70%",
            height: "80%",
            display: "inline-block",
            backgroundColor: "#d3d3d3",
            float: "left",
            verticalAlign: "top",
          }}>
            <div
              key={"droppableStatic"}
              className="staticDropArea"
              onDrop={(e) => {
                let dropData = JSON.parse(e.dataTransfer.getData("data"));
                this.dropTrigger(dropData.questionId, "1")
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
              onDragLeave={(e) => {
                document.querySelectorAll(".dropArea").forEach(el => {
                  el.style.display = "none";
                });
              }}
            ></div>
            {Object.keys(this.props.fotoHoofdstukElementen.hoofdstukToIds)
              .includes(this.props.hoofdstuk.id) ?
              Object.keys(this.props.fotoHoofdstukElementen.hoofdstukToIds[
                this.props.hoofdstuk.id
              ]).map(order => {
                let fotoElementen = this.props.fotoHoofdstukElementen;
                let foto = fotoElementen.byId[fotoElementen.hoofdstukToIds[
                  this.props.hoofdstuk.id
                ][order]];
                return <React.Fragment>
                  <div
                    className="sideBarElement"
                    draggable
                    onDragStart={(e) => this.onDragStart(e, JSON.stringify({
                      type: "foto",
                      questionId: foto.questionId,
                    }))}
                    onDrop={(e) => {
                      let dropData = JSON.parse(e.dataTransfer.getData("data"));
                      this.dropTrigger(dropData.questionId,
                      parseInt(order, 10) + 1);
                      document.querySelectorAll(".dropArea").forEach(el => {
                        el.style.display = "none";
                      });
                    }}
                    onDragOver={(e) => {
                      let target = e.target;
                      while(target.getAttribute("class") !== 'sideBarElement'){
                        target = target.parentNode;
                      }
                      target.nextSibling.style.display = "block";
                      e.preventDefault();
                    }}
                    onDragLeave={(e) => {
                      document.querySelectorAll(".dropArea").forEach(el => {
                        el.style.display = "none";
                      });
                    }}
                    onClick={() => {
                      this.setState({
                        selectedVraag: foto.id,
                      });
                    }}
                  >
                    <img
                      src={"/icons/camera.svg"}
                      className="svgImg"
                    />
                    <span className="elementName" style={{
                      width: '95%',
                    }}>
                      {this.props.questions.byId[foto.questionId].vraag}
                      <span className="deleteButton" style={{
                        float: 'right'
                      }}>
                        <img
                          src={"/letters/x.svg"}
                          alt="X"
                          className="svgImg"
                          onClick={() => {
                            if(this.state.selectedVraag === foto.id){
                              this.setState({
                                selectedVraag: "0"
                              });
                            }
                            FotoHoofdstukApiHelper.deleteHoofdstukElement({
                              hoofdstukElementId: foto.id,
                            }, (data) => {
                              this.props.actions.removeFotoHoofdstukElement({
                                hoofdstukElementId: foto.id,
                              });
                              this.props.actions.fixFotoOrders({
                                id: this.props.match.params.hoofdstukId,
                                order: data.order,
                              })
                            })
                          }}
                        />
                      </span>
                    </span>
                  </div>
                  <div class="dropArea" style={{display: 'none'}}></div>
                </React.Fragment>
              })
              : null}
          </div>
        </React.Fragment> :
          <React.Fragment>
          <table className="settingsTable">
            <tbody>
              <tr>
                <td>
                  Titel (inhoudsopgave)
                </td>
                <td>
                  <input
                    type="text"
                    name="title"
                    value={this.props.hoofdstuk.title}
                    onChange={(e) => this.changeForm(e)}
                  />
                </td>
                <td>
                  Paginanummering:
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="showPageNumbers"
                    checked={this.props.hoofdstuk.showPageNumbers === "1" ? "checked"
                      : false}
                    onChange={(e) => this.changeForm(e)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  Interne naam:
                </td>
                <td>
                  <input
                    type="text"
                    name="internalTitle"
                    value={this.props.hoofdstuk.internalTitle}
                    onChange={(e) => this.changeForm(e)}
                  />
                </td>
                <td>
                  Enkel tonen bij trigger:
                </td>
                <td>
                  {Object.keys(this.props.triggers.byId).length > 0 ?
                    <select
                      onChange={(e) => this.changeForm(e)}
                      name="tonenByTrigger"
                    >
                      <option value="null">
                        Geen trigger
                      </option>
                      {Object.keys(this.props.triggers.byId).map((key) => {
                          
                        // console.log(this.props.questions.byId);
                        //console.log(key);
                            console.log(this.props.triggers.byId);
                          
                        return <option value={this.props.triggers.byId[key].id} >
                        {this.props.questions.byId[this.props.triggers.byId[key].vraagId].vraag} &#x3E; {this.props.triggers.byId[key].dependecy}
                      </option>
                      })}
                    </select>
                    : "Er zijn geen triggers aanwezig in het systeem"}
                </td>
              </tr>
              <tr>
                <td>
                  Hoofdstuk type:
                </td>
                <td>
                  {this.props.hoofdstuk.isGeneric === "1" ? "Generiek" : "Specifiek"}
                </td>
                <td>
                  Groep/Pakket:
                </td>
                <td>
                  {this.props.linkedPakketten.map(pakket => {
                    return (<div key={"pakket" + pakket.pakket}>
                      {pakket.pakket + " > " + pakket.template}
                    </div>);
                  })}
                </td>
              </tr>
              <tr>
                <td>
                  Inhoudsopgave:
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="showInIndex"
                    checked={this.props.hoofdstuk.showInIndex === "1" ? "checked"
                      : false}
                    onChange={(e) => this.changeForm(e)}
                  />
                </td>
              </tr>
              <tr>
                <td className="goToTd" colSpan="4">
                  <Link
                    to={process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.hoofdstuk.id + "/edit"}
                  >
                    <button>
                      Naar inhoud van het hoofdstuk
                    </button>
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
        }
        <div style={{clear: "both"}} className="buttonBar">
          <button
            onClick={() => {
              this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/");
            }}
          >
            Terug
          </button>
            <button
              onClick={() => {
                HoofdstukApiHelper.duplicateHoofdstuk({
                  id: this.props.hoofdstuk.id
                }, (data) => {
                  this.props.actions.addHoofdstuk(data.hoofdstuk);
                  data.elementen.forEach(el => {
                    this.props.actions.addHoofdstukElement(el);
                  });
                  this.props.history.push(process.env.PUBLIC_URL + "/Hoofdstukken/" + data.hoofdstuk.id);
                })
              }}
            >
              Dupliceer
            </button>
          <button
            onClick={() => {
              //Update generic gegevens
              let pageNumbers = "0";
              let showIndex = "0";
              if(this.props.hoofdstuk.type !== "foto"){
                if(document.querySelector("input[name=showInIndex]").checked){
                  showIndex = "1";
                }
                if(document.querySelector("input[name=showPageNumbers]").checked){
                  pageNumbers = "1";
                }
              }

              let data = {
                id: this.props.hoofdstuk.id,
                title: document.querySelector("input[name=title]").value,
                //internalTitle: document.querySelector("input[name=internalTitle]").value,
                showIndex: showIndex,
                showPageNumbers: pageNumbers,
                tonenByTrigger: this.props.hoofdstuk.tonenByTrigger,
              };
              //console.log(this.props.hoofdstuk.tonenByTrigger);
              HoofdstukApiHelper.updateHoofdstuk(data, () => {
                alert("Bedankt, uw template is opgeslagen");
              });

              // Update all individual elements
              if(this.props.hoofdstukElementen.length > 0) {
                this.props.hoofdstukElementen.forEach(hoofdstukElement => {
                  if(hoofdstukElement.type === "vragen"){

                  } else if(hoofdstukElement.type === "text"){

                  }
                });
              }
            }}
          >
            Bewaar hoofdstuk
          </button>
        </div>
      </React.Fragment>
    )
  }
}

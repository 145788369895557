import React from 'react';

export default class RightSideComponent extends React.Component {

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  render(){
    return (
      <React.Fragment>
      {Object.keys(this.props.hoofdstukken).map(id => {
        return (
          <div
            className="draggableElement"
            key={"draggable" + id}
            draggable="true"
            onDragStart={(e) => this.onDragStart(e, JSON.stringify({
              type: "hoofdstuk",
              hoofdstukId: id,
            }))}
          >
            <img
              src={"/letters/h.svg"}
              alt="H"
              className="svgImg"
            />
            <span className="elementName">
              {this.props.hoofdstukken[id].internalTitle}
            </span>
        </div>);
      })}
      </React.Fragment>
    )
  }

}

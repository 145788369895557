import React from 'react';

import SideBarComponent from './SideBarComponent';
import { connect } from 'react-redux';

class SideBarContainer extends React.Component {
  render() {
    return (
      <SideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let hoofdstuk = state.hoofdstuk.byId[ownProps.match.params.hoofdstukId];
  //Build allowed question ids:
  let possibleQuestionIds = [];
  if(hoofdstuk !== undefined && hoofdstuk.pakketId !== null){
    let pakket = state.pakket.byId[hoofdstuk.pakketId];
    if(Object.keys(state.pakket.pakketToForm).includes(pakket.id)){
      state.pakket.pakketToForm[pakket.id].forEach(key => {
        if(Object.keys(state.formulier.formulierToQuestion).includes(key)){
          state.formulier.formulierToQuestion[key].forEach(vraag => {
            possibleQuestionIds.push(vraag.vraagId);
          });
        }
      })
    }
  }
  return {
    hoofdstuk: hoofdstuk,
    questions: state.question,
    possibleQuestionIds: possibleQuestionIds
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer);

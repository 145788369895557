import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

require("./style.css")


export default class SidebarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  render(){
    let params = this.props.match.params
    return (
      <div>
        <input
          type="search"
          value={this.state.query}
          onChange={(e) => this.setState({
            query: e.target.value
          })}
          placeholder="Zoeken"
        />
        {Object.keys(this.props.hoofdstukElements.byId).length > 0 ?
            Object.keys(this.props.hoofdstukElements.byId).map(key => {
              let el = this.props.hoofdstukElements.byId[key];
              let boEl = this.props.backOfficeElements.byId[el.elementId];
              if(el.type === "backoffice" && boEl !== undefined){
                if(
                  this.state.query === ""
                  || boEl.naam.includes(this.state.query)
                ){
                  return <Link
                    to={process.env.PUBLIC_URL + "/Hoofdstukken/" + params.hoofdstukId + "/editBackoffice/" +
                      el.id}
                    className='sideBarElement'
                    key={key}
                  >
                    <div
                        class="sideBarElement"
                      >
                      <img
                        src={"/letters/2.svg"}
                        alt="2"
                        class="svgImg"
                       />
                       <span class="elementName">
                          {boEl.naam}
                       </span>
                     </div>
                   </Link>
                 }
               }
            })
          : null}
      </div>
    )
  }
}

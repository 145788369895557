import React from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextManagerApiHelper } from '../../../../apiHandlers/TextManagerApiHelper';

require("./style.css");

export default class TextManagerAddSideBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      catNaam: "",
      pakketGroep: "leeg",
      query: "",
    }
  }

  render() {
    let params = this.props.match.params;
    return (
      <div>
        {this.state.showPopup ?
          <div
            id="popup"
          >
            <span className="popupTitle">
              Type hoofdstuk (generiek, vragen, foto)
            </span>
            <br /> <br />
            Nieuwe categorie naam:
            <input
              type="text"
              value={this.state.catNaam}
              onChange={(e) => {
                this.setState({
                  catNaam: e.target.value,
                })
              }}
            /><br />
            Pakketgroepnaam:
            <select
              onChange={(e) => {
                this.setState({
                  pakketGroep: e.target.value,
                })
              }}
            >
              <option value="leeg">
                Kies pakketgroep
              </option>
              {Object.keys(this.props.pakketGroepen).map(key => {
                let pakketGroep = this.props.pakketGroepen[key]
                return <option value={pakketGroep.id}>
                  {pakketGroep.naam}
                </option>
              })}
            </select>
            <br />
            <button onClick={() => this.setState({
              showPopup: false,
              catNaam: "",
              pakketGroep: "leeg",
            })}>
              Annuleer
            </button>
            <button
              disabled={this.state.pakketGroepId !== "leeg"
                && this.state.name !== undefined
                && this.state.name.length > 0}
              onClick={() => {
                let obj = {
                  pakketGroepId: this.state.pakketGroep,
                  name: this.state.catNaam,
                };
                TextManagerApiHelper.createCategory(obj, (data) => {
                  obj.id = data.id
                  this.props.actions.addCategory(obj);
                  this.setState({
                    showPopup: false,
                    catNaam: "",
                    pakketGroep: "leeg",
                  });
                })
              }}
            >
              Bewaar Categorie
            </button>
          </div>
        : null}
        <button
          onClick={() => {
            this.setState({
              showPopup: true,
            });
          }}
        >
          <label>
            <FontAwesomeIcon icon="plus-circle" className="new" />
              Nieuw
          </label>
        </button>
        <input
          type="search"
          value={this.state.query}
          onChange={(e) => this.setState({
            query: e.target.value
          })}
          placeholder="Zoeken"
        />
        {Object.keys(this.props.categories).map(catKey => {
          if(this.state.query === "" || this.props.categories[catKey].name
            .includes(this.state.query)
          ){
            return <div
              className='sideBarElement'
            >
              <img
                src={"/letters/c.svg"}
                alt="c"
                className="svgImg"
              />
              <span className="elementName">
                {this.props.categories[catKey].name}
              </span>
            </div>
          }
        })}
      </div>
    )
  }
}

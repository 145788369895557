export const question = {
  state: {
    byId: {},
    allIds: [],
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int
    //   content: string
    // }
    addQuestion(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      return {
        byId: newById,
        allIds: allIds,
      }
    },
  }
}

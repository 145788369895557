import React from 'react';
// import CKEditor from 'ckeditor4-react';
import CKEditor from "react-ckeditor-component";

export default class TextElementComponent extends React.Component {
  render() {
    return (
      <div className="textElement">
      <CKEditor
        activeClass="p10"
        content={this.props.textElement.content}
        events={{
          "change": (e) => {
            this.props.actions.editTextElement({
              id: this.props.elementId,
              content: e.editor.getData(),
            });
          }
        }}
        config={{
          getText: () => {
            this.props.history.push(process.env.PUBLIC_URL + "/TextManager/" +
              this.props.match.params.hoofdstukId + "/" + this.props.elementId
              + "/")
          },
          saveText: () => {
            this.props.actions.saveText({
              text: this.props.textElement.content
            });
            this.props.history.push(process.env.PUBLIC_URL + "/TextManager/" +
              this.props.match.params.hoofdstukId + "/" + this.props.elementId
              + "/add")
          },
          addTwoColumn: () => {
          this.props.history.push(process.env.PUBLIC_URL + "/TwoColumnTool/" +
            this.props.match.params.hoofdstukId + "/" + this.props.elementId
            + "/")
          },
        }}
        scriptUrl={process.env.PUBLIC_URL + "/ckeditor/ckeditor.js"}
       />
      </div>
    )
  }
}

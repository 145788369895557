import { getFetch } from '../utils/fetches';

export const PdfApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

   // Expected data: {
   //   id: int (templateId)
   // }
   apiFuncs.getPdf = function(data, callback){   
     return getFetch(apiUrl + "Pdf?id=" + data.id, callback);
   }

   return apiFuncs;
}());

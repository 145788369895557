export const backOfficeQuestion = {
  state: {
    byId: {},
    allIds: [],
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int,
    //   backOfficeQuestionElementId: int,
    //   questionId: int,
    //   alternateQuestion: str,
    //   order: int,
    // }
    addQuestion(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      return {
        byId: newById,
        allIds: allIds,
      }
    },
    deleteBackOfficeSubElement(state, payload){
      let newState = state;
      delete newState.byId[payload.id];
      newState.allIds.splice(newState.allIds
        .indexOf(payload.id), 1);
      return {
        allIds: newState.allIds,
        byId: newState.byId,
      };
    },
    
    // Expected payload:
    // {
    //   id: int,
    //   key: val
    // }
    changeQuestionElement(state, payload){
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      let newById = Object.assign({}, state.byId, obj);
      return Object.assign({}, state, {
        byId: newById,
      });
    },
  }
}

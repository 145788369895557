import React from 'react';

import HomeComponent from './HomeComponent';
import { connect } from 'react-redux';

class HomeContainer extends React.Component {
  render() {
    return (
      <HomeComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addPakket: dispatch.pakket.addPakket,
      addTemplate: dispatch.template.addTemplate,
      addHoofdstuk: dispatch.hoofdstuk.addHoofdstuk,
      linkTemplateHoofdstuk: dispatch.templateHoofdstuk.linkTemplateHoofdstuk,
      addHoofdstukElement: dispatch.hoofdstukElement.addHoofdstukElement,
      addTextElement: dispatch.textElement.addTextElement,
      addQuestionElement: dispatch.questionElement.addQuestionElement,
      addQuestion: dispatch.question.addQuestion,
      addFormulier: dispatch.formulier.addFormulier,
      addQuestionToFormulier: dispatch.formulier.addQuestionToFormulier,
      addUser: dispatch.users.addUser,
      setRechtenToUser: dispatch.users.setRechtenToUser,
      addPakketGroep: dispatch.pakketGroep.addPakketGroep,
      addPakketGroepToUser: dispatch.users.linkUserToPakketGroep,
      addBackOfficeElement: dispatch.backOfficeQuestionElement.addBackOfficeElement,
      addBackOfficeQuestion: dispatch.backOfficeQuestion.addQuestion,
      linkBackOfficeQuestionToElement: dispatch.backOfficeQuestionElement.linkQuestionToElement,
      addTrigger: dispatch.trigger.addTrigger,
      addTwoColumn: dispatch.twoColumn.addTwoColumn,
      addTwoColumnRow: dispatch.twoColumn.addRow,
      addTextManagerCategory: dispatch.textManager.addCategory,
      addTextManagerText: dispatch.textManager.addText,
      addFotoHoofdstukElement: dispatch.fotoHoofdstukElement.addFotoHoofdstukElement,
      addUploadPage: dispatch.template.addUploadPage,
      addUploadHoofdstukElement: dispatch.uploadHoofdstuk.addUploadHoofdstukElement,
      addFormToPakket: dispatch.pakket.addFormToPakket,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);

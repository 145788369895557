import { getFetch } from '../utils/fetches';

export const QuestionApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/rest/";

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */

   apiFuncs.getQuestion = function(questionId, callback){
     return getFetch(apiUrl + "Vraag?id=" + questionId, callback);
   }

   apiFuncs.getAllQuestions = function(callback){
     return getFetch(apiUrl + "Vraag", callback);
   }
  return apiFuncs;
}());

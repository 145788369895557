export const HoofdstukApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  apiFuncs.generateHeaders = function(){
    return new Headers({
      'Authorization': sessionStorage.getItem("jwt"),
      'content-type': 'application/json',
    });
  }

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */
  apiFuncs.getHoofdstukken = function(callback){
    return fetch(apiUrl + "Hoofdstuk", {
      headers: this.generateHeaders(),
    })
    .then(result => result.json())
    .then(data => callback(data));
  }

  apiFuncs.createHoofdstuk = function(data, callback){
    return fetch(apiUrl + "Hoofdstuk?createNewHoofdstuk=true", {
      headers: this.generateHeaders(),
      method: 'POST',
      body: JSON.stringify({
        title: data.title,
        internalTitle: data.internalTitle,
        type: data.type,
        showIndex: data.showIndex,
        showPageNumbers: data.showPageNumbers,
        isGeneric: data.isGeneric,
        pakketId: data.pakketId,
      })
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // {
  //   id: int
  // }
  apiFuncs.duplicateHoofdstuk = function(data, callback){
    return fetch(apiUrl + "Hoofdstuk?duplicateHoofdstuk=true", {
      headers: this.generateHeaders(),
      method: 'POST',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }


  // Expected data:
  // {
  //   title: string,
  //   internalTitle: string,
  //   showIndex: bool (int),
  //   showPageNumbers: bool (int),
  //   isGeneric: bool (int),
  //   pakketId: nullable int
  // }

    apiFuncs.updateHoofdstuk = function(data, callback){
      return fetch(apiUrl + "Hoofdstuk", {
        headers: this.generateHeaders(),
        method: 'PUT',
        body: JSON.stringify(data)
      })
      .then(response => response.json())
      .then(data => callback(data));
    }

  return apiFuncs;
}());

export const users = {
  state: {
    byId: {},
    allIds: [],
    userToPakketGroep: {},
    userToRechten: {},
  },
  reducers: {
    // Expected data
    // {
    //   id: int,
    //   username: str,
    //   name: str,
    //   email: str,
    //   online: bool(int)
    // }
    addUser: function(state, payload) {
      let obj = {};
      obj[payload.id] = payload;
      let rechten = {};
      rechten[payload.id] = {
        '1': {
          Aanmaken: '0',
          Wijzigen: '0',
          Dupliceren: '0',
          Offline: '0',
          Inzien: '0'
        },
        '2': {
          Aanmaken: '0',
          Wijzigen: '0',
          Inzien: '0'
        },
        '3': {
          Aanmaken: '0',
          Wijzigen: '0',
          Dupliceren: '0'
        },
        '4': {
          Aanmaken: '0',
          Wijzigen: '0',
          Dupliceren: '0'
        }
      }
      return {
        byId: Object.assign({}, state.byId, obj),
        allIds: [...state.allIds, payload.id],
        userToPakketGroep: state.userToPakketGroep,
        userToRechten: Object.assign({}, state.userToRechten, rechten),
      };
    },
    // {
    //   id: int,
    //   key: value,
    // }
    changeUser: function(state, payload){
      let user = state.byId[payload.id];
      user = Object.assign({}, user, payload);
      let obj = {};
      obj[payload.id] = user;
      return {
        byId: Object.assign({}, state.byId, obj),
        allIds: state.allIds,
        userToPakketGroep: state.userToPakketGroep,
        userToRechten: state.userToRechten,
        userToRechten: state.userToRechten,
      };
    },
    // {
    //   userId: int,
    //   pakketGroepId: int,
    // }
    linkUserToPakketGroep: function(state, payload){
      let userToPakketGroep = state.userToPakketGroep;
      if(Object.keys(userToPakketGroep).includes(payload.userId)){
        userToPakketGroep[payload.userId] = [
          ...userToPakketGroep[payload.userId], payload.pakketGroepId
        ]
      } else {
        userToPakketGroep[payload.userId] = [
          payload.pakketGroepId
        ];
      }
      return {
        byId: state.byId,
        allIds: state.allIds,
        userToPakketGroep: userToPakketGroep,
        userToRechten: state.userToRechten,
      };
    },

    // {
    //   userId: int,
    //   pakketGroepId: int,
    // }
    unlinkUserToPakketGroep: function(state, payload){
      let userToPakketGroep = {};
      let obj = state.userToPakketGroep[payload.userId];
      let before = obj.splice(0, obj.indexOf(payload.pakketGroepId));
      let after = obj.splice(obj.indexOf(payload.pakketGroepId) + 1);

      userToPakketGroep[payload.userId] = before.concat(after);
      let newState = {
        byId: state.byId,
        allIds: state.allIds,
        userToPakketGroep: Object.assign({}, state.userToPakketGroep,
          userToPakketGroep),
        userToRechten: state.userToRechten,
      };
      return newState;
    },
    // {
    //   userId: int,
    //   catId: int,
    //   type: str,
    //   allow: int,
    // }
    setRechtenToUser: function(state, payload){
      let oldRechten = state.userToRechten;
      // console.log("oldRechten", oldRechten)
      let newUserRechten = {};
      //Check if user has rechten assigned

      if(Object.keys(oldRechten).includes(payload.userId)){
        let userRechten = oldRechten[payload.userId];
        let rechtenObj = {};
        rechtenObj[payload.type] = payload.allow;
        if(Object.keys(userRechten).includes(payload.catId)){
          let catObj = userRechten[payload.catId];
          let newRecht = {};
          newRecht[payload.type] = payload.allow;
          let newCat = {};
          newCat[payload.catId] = Object.assign({}, catObj,
            newRecht);
          let userObj = {};
          userObj[payload.userId] = Object.assign({},
            oldRechten[payload.userId], newCat
          );
          newUserRechten = Object.assign({}, oldRechten, userObj);
        } else{
          let catObj = {};
          catObj[payload.catId] = rechtenObj;
          let userObj = {};
          userObj[payload.userId] = Object.assign({},
            oldRechten[payload.userId], catObj
          )
          newUserRechten = Object.assign({}, oldRechten, userObj);
        }
      } else {
        let obj = {};
        obj[payload.userId] = {};
        obj[payload.userId][payload.catId] = {};
        obj[payload.userId][payload.catId][payload.type] = payload.allow;
        newUserRechten = Object.assign({}, oldRechten, obj);
      }
      return {
        byId: state.byId,
        allIds: state.allIds,
        userToPakketGroep: state.userToPakketGroep,
        userToRechten: newUserRechten,
      };
    }
  }
}

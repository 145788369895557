import React from 'react';
// import TemplateDetailContainer from '../TemplateDetail/TemplateDetailContainer';

export default class MainComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="title">
          Templates
        </div>
        <div className="melding">In dit scherm kun je templates aanmaken en wijzigen.</div>
      </React.Fragment>
    )
  }
}

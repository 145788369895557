import React from 'react';
import { BrowserRouter as Router , NavLink, Switch} from "react-router-dom";

import NavigationContainer from '../Navigation/NavigationComponent.js';
import routes from '../../../Routes';
import { TemplateApiHelper } from '../../../apiHandlers/TemplateApiHelper';
import { HoofdstukApiHelper } from '../../../apiHandlers/HoofdstukApiHelper';
import { PakketApiHelper } from '../../../apiHandlers/PakketApiHelper';
import { HoofdstukElementApiHelper }
    from '../../../apiHandlers/HoofdstukElementApiHelper';
import { QuestionApiHelper } from '../../../apiHandlers/QuestionApiHelper';
import { FormulierApiHelper } from '../../../apiHandlers/FormulierApiHelper';
import { UserApiHelper } from '../../../apiHandlers/UserApiHelper';
import { PakketGroepApiHelper } from '../../../apiHandlers/PakketGroepApiHelper';
import { TwoColumnApiHelper } from '../../../apiHandlers/TwoColumnApiHelper';
import { TextManagerApiHelper } from '../../../apiHandlers/TextManagerApiHelper';
import { UploadHoofdstukApiHelper } from '../../../apiHandlers/UploadHoofdstukApiHelper';

require('./style.css');

export default class HomeComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      contentLoaded: false,
    };
    this.loadData();
  }

  //Laad data van database in
  loadData() {
    let gottenHoofdstukken = [];
    let formulierenLinked = [];
    let backOfficeElementen = [];
    let twoColumnsElementen = [];
    let fotoHoofdstukken = [];
    let userIds = [];   
    return Promise.all([
      TwoColumnApiHelper.getAllTwoColumns(data => {
        if(data.length > 0){
          data.forEach(twoColumn => {
            twoColumnsElementen.push(twoColumn.id);
            this.props.actions.addTwoColumn(twoColumn);
          })
        }
      }),
      UserApiHelper.getUsers((data) => {
        if(data.length > 0){
          data.forEach((user) => {
            userIds.push(user.id);
            this.props.actions.addUser(user);
          });
        }
      }),
      PakketGroepApiHelper.getAllPakketGroepen(data => {
        if(typeof data === "string" && data.length > 0){
          data = JSON.parse(data);
        }
        if(data.length > 0){
          data.forEach(pakketGroep => {
            this.props.actions.addPakketGroep(pakketGroep);
          })
        }
      }),
      TemplateApiHelper.getTemplates((data) => {
        if(data.length > 0){
          data.forEach((template) => {
            this.props.actions.addTemplate(template);
          })
        }
      }),
      HoofdstukApiHelper.getHoofdstukken((data) => {
        if(data.length > 0){
          data.forEach((hoofdstuk) => {
            if(hoofdstuk.type === "foto"){
              fotoHoofdstukken.push(hoofdstuk.id);
            } else{
              gottenHoofdstukken.push(hoofdstuk.id);
            }
            this.props.actions.addHoofdstuk(hoofdstuk);
          })
        }
      }),
      TemplateApiHelper.getHoofdstukkenForTemplates((data) => {
        if(data.length > 0){
          data.forEach(koppeling => {
            this.props.actions.linkTemplateHoofdstuk(koppeling);
          })
        }
      }),
      PakketApiHelper.getPakket((data) => {
        //console.log("data: ", data);
        if(typeof data === "string" && data.length > 0){
          data = JSON.parse(data);
        }
        if(data.length > 0){
          data.forEach(pakket => {
            this.props.actions.addPakket(pakket)
          })
        }
      }),
      FormulierApiHelper.getFormulierPakket(data => {
        if(typeof data === "string" && data.length > 0){
          data = JSON.parse(data);
        }
        if(data.length > 0){
          data.forEach(obj => {
            this.props.actions.addFormToPakket(obj)
          })
        }
      }),
      HoofdstukElementApiHelper.getHoofdstukElementen((data) => {
        if(data.length > 0){
          data.forEach(hoofdstukElement => {
            this.props.actions.addHoofdstukElement(hoofdstukElement)
          })
        }
      }),
      QuestionApiHelper.getAllQuestions(data => {
        if(typeof data === "string" && data.length > 0){
          data = JSON.parse(data);
        }
        Object.keys(data).forEach(questionId => {
          this.props.actions.addQuestion(data[questionId]);
        })
      }),
      FormulierApiHelper.getAllFormulieren(data => {
        if(typeof data === "string" && data.length > 0){
          data = JSON.parse(data);
        }
        Object.keys(data).forEach(key => {
          formulierenLinked.push(data[key].id);
          this.props.actions.addFormulier(data[key])
        });
      }),
       UploadHoofdstukApiHelper.getAllUploadPages(data => {
         if(data.pages.length > 0){
           data.pages.forEach(page => {
             this.props.actions.addUploadPage({
               templateId: page.templateId,
               uploadPageId: page.id
             })
           })
         }
         if(Object.keys(data.questions).length > 0){
           Object.keys(data.questions).forEach(key => {
             data.questions[key].forEach(question => {
               this.props.actions.addUploadHoofdstukElement(question);
             });
           })
         }
       })
    ]).then(() => {
      let promiseArray = [];
      promiseArray.push(TextManagerApiHelper.getAllTextCategories(data => {
        if(data.length > 0){
          data.forEach(category => {
            this.props.actions.addTextManagerCategory(category);
          })
        }
      }));
      promiseArray.push(TextManagerApiHelper.getAllTexts(data => {
        if(data.length > 0){
          data.forEach(text => {
            this.props.actions.addTextManagerText(text);
          })
        }
      }))
      //Get pakketten linked to user
      if(userIds.length > 0){
        userIds.forEach(id => {
          promiseArray.push(UserApiHelper.getPakketGroepenForUsers({
            userId: id
          }, (data) => {
            Object.keys(data).forEach(key => {
              this.props.actions.addPakketGroepToUser({
                userId: data[key].userId,
                pakketGroepId: data[key].pakketGroepId,
              });
            })
          }));
          promiseArray.push(UserApiHelper.getUserRechten({
            userId: id
          }, (data) => {
            Object.keys(data).forEach(key => {
              this.props.actions.setRechtenToUser(data[key]);
            })
          }))
        });
      }

      if(twoColumnsElementen.length > 0){
        twoColumnsElementen.forEach(twoColumId => {
          TwoColumnApiHelper.getTwoColumnRows(twoColumId, data => {
            data.forEach(twoColumnRow => {
              this.props.actions.addTwoColumnRow(twoColumnRow);
            })
          })
        });
      }

      if(fotoHoofdstukken.length > 0) {
        fotoHoofdstukken.forEach(id => {
          promiseArray.push(HoofdstukElementApiHelper
            .getElementenForFotoHoofdstuk(id, data => {
              data.forEach(element =>
                this.props.actions.addFotoHoofdstukElement(element)
              );
            })
          )
        });
      }
      //Get the data that's dependable from state
      if(gottenHoofdstukken.length > 0) {
        gottenHoofdstukken.forEach(id => {
          promiseArray.push(HoofdstukElementApiHelper
            .getElementenForHoofdstuk(id, data => {
              if(data['text'].length > 0) {
                data['text'].forEach(element => {
                  this.props.actions.addTextElement(element)
                })
              }
              if(Object.keys(data['vragen']).length > 0) {
                Object.keys(data['vragen']).forEach(key => {
                  data['vragen'][key].forEach(vraag => {
                    this.props.actions.addQuestionElement(vraag);
                  })
                })
              }
              if(Object.keys(data['backoffice']).length > 0) {
                Object.keys(data['backoffice']).forEach(key => {
                  this.props.actions.addBackOfficeElement(data['backoffice'][key]);
                  
                  //if(data['backoffice'][key] != null){
                    backOfficeElementen.push(data['backoffice'][key].id);
                  //}
                 
                })
              }
            })
          )
        });
      }
      if(formulierenLinked.length > 0){
        formulierenLinked.forEach(id => {
          FormulierApiHelper.getQuestionsFromFormulier(id, data => {
            Object.keys(data).forEach(key => {
              FormulierApiHelper.getTriggers(data[key].id, (res) => {
                if(res.length > 0 && res !== "[]"){
                    res.forEach(res => this.props.actions.addTrigger(res));
                  }
              });
              this.props.actions.addQuestionToFormulier({
                formId: data[key].id_formulier,
                vraagId: data[key].id_vraag,
                type: data[key].type,
                formulierRegelId: data[key].id,
              });
            })
          });
        });
      }
      return Promise.all(promiseArray);
    }).then(() => {
      let promiseArray = [];
      if(backOfficeElementen.length > 0){
        backOfficeElementen.forEach(id => {
          HoofdstukElementApiHelper.getBackOfficeSubElement({
            backOfficeElementId: id
          }, (data) => {
            data.forEach(question => {
              this.props.actions.addBackOfficeQuestion(question);
              this.props.actions.linkBackOfficeQuestionToElement({
                order: question.order,
                id: question.id,
                backOfficeQuestionElementId: id,
              });
            })
          });
        });
      }
      return Promise.all(promiseArray);
    }).then(() => {
      this.setState({
        contentLoaded: true,
      });
    });
  }

  render(){
    return (
      <Router>
      {this.state.contentLoaded ?
          <div className="container">
            <header>
              <div className="logo">
                <NavLink to={process.env.PUBLIC_URL + "/"}>
                  <img
                    alt="logo"
                    src={"https://dev.fintoolbox.nl/files/logo.png"}
                   />
                </NavLink>
              </div>
              <nav>
                <NavigationContainer />
              </nav>
              <div className="logo" style={{marginTop: "-15px"}}>
                <a href="https://www.cardsolutions.nl/" target="_blank" style={{textDecoration: "none" , color: "grey" , fontSize: "10px"}}>Development:<br /><img src="https://www.cardsolutions.nl/CMSDATA/templates/-home2017respsol/images/CARDSolutions.svg" style={{height: "60%", width: "140px"}} />
                 </a>
              </div>
            </header>
            <div className="main">
              <div className="mainContent">
                <Switch>
                  {routes.left}
                </Switch>
              </div>
              <div className="sideBar">
                <Switch>
                  {routes.right}
                </Switch>
              </div>
            </div>
          </div> :
          <div>
            Loading
          </div>
        }
      </Router>
    )
  }
}

//
export const textElement = {
  state: {
    byId: {},
    allIds: [],
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int
    //   content: string
    // }
    addTextElement(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      return {
        byId: newById,
        allIds: allIds,
      }
    },

    // Expected payload:
    // {
    //   id: int,
    //   content: str
    // }
    editTextElement(state, payload){
      let obj = {};
      obj[payload.id] = payload;

      return {
        allIds: state.allIds,
        byId: Object.assign({}, state.byId, obj)
      };
    }
  }
}

import { getFetch } from '../utils/fetches';

export const TemplateApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";


  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */

  apiFuncs.getTemplates = function(callback){
    return getFetch(apiUrl + "Template", callback);
  };

  apiFuncs.getHoofdstukkenForTemplates = function(callback){
    return getFetch(apiUrl + "Template?getHoofdstukLinks=true", callback);
  };

  apiFuncs.addNewTemplate = function(callback){
    return getFetch(apiUrl + "Template?createNewTemplate=true", callback, {
      method: "POST",
      body: JSON.stringify({
        name: "Nieuw template"
      }),
    });
  }

  apiFuncs.createHoofdstukTemplate = function(data, callback){
    return getFetch(apiUrl + "Template?createTemplateHoofdstuk=true", callback, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  apiFuncs.deleteTemplateHoofdstuk = function(data, callback){
    return getFetch(apiUrl + "Template?deleteTemplateHoofdstuk=true", callback, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
  }
  // {
  //     templateId: int,
  //     name: str,
  //     pakketId: int,
  //     showUploadPage: bool (0|1)
  // }
  apiFuncs.updateTemplate = function(data, callback){
    return getFetch(apiUrl + "Template", callback, {
      method: "PUT",
      body: JSON.stringify(data),
    })
  }

  apiFuncs.duplicateTemplate = function(data, callback){
    return getFetch(apiUrl + "Template?duplicateTemplate=true", callback, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  return apiFuncs;
}());

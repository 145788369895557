export const fotoHoofdstukElement = {
  state: {
    byId: {},
    hoofdstukToIds: {},
    allIds: [],
  },
  reducers: {
    // {
    //   hoofdstukToIds: int,
    // }
    removeFotoHoofdstukElement: function(state, payload){
      let newById = {};
      let newHoofdstukToIds = {};
      Object.keys(state.hoofdstukToIds).forEach(key => {
        let tmpObj = {};
        Object.keys(state.hoofdstukToIds[key]).forEach(tmp => {
          if(state.hoofdstukToIds[key][tmp] !== payload.hoofdstukElementId){
            tmpObj[tmp] = state.hoofdstukToIds[key][tmp]
          }
        })
        newHoofdstukToIds[key] = tmpObj;
      });

      Object.keys(state.byId).filter(key => {
        return key !== payload.hoofdstukElementId
      }).forEach(data => {
        newById[data] = state.byId[data];
      });

      // return state;
      let newState = Object.assign({}, state, {
        byId: newById,
        hoofdstukToIds: newHoofdstukToIds,
      });
      return newState;
    },

    // Expected payload:
    // {
    //   id: int,
    //   hoofdstukId: int,
    //   questionId: int,
    //   text: str,
    //   order: str
    // }
    addFotoHoofdstukElement: function(state, payload){
      let newById = state.byId;
      let obj = {};
      obj[payload.id] = {
        id: payload.id,
        hoofdstukId: payload.hoofdstukId,
        questionId: payload.questionId,
        text: payload.text,
        order: payload.order
      };

      newById = Object.assign({}, newById, obj);
      let newHoofdstukToIds = state.hoofdstukToIds;
      let objToAdd = {};
      objToAdd[payload.order] = payload.id;
      if(Object.keys(newHoofdstukToIds).includes(payload.hoofdstukId)){
        newHoofdstukToIds[payload.hoofdstukId] = Object.assign({},
          newHoofdstukToIds[payload.hoofdstukId], objToAdd);
      } else {
        newHoofdstukToIds[payload.hoofdstukId] = objToAdd;
      }
      return {
        byId: newById,
        hoofdstukToIds: newHoofdstukToIds,
        allIds: [...state.allIds, payload.id]
      };
    },

    // Expected payload:
    // {
    //   id: hoofdstukId,
    //   order: obj
    // }
    fixOrder: function(state, payload){
      let object = {};
      object[payload.id] = {};
      if(payload.order.length > 0){
        payload.order.forEach(obj => {
          object[payload.id][obj.order] = obj.id;
        });
      }
      let res =  Object.assign({}, state, {
        hoofdstukToIds: Object.assign({}, state.hoofdstukToId, object)
      });
      return res;
    },

    // {
    //   id: int
    //   key: val,
    // }
    changeFotoElement(state, payload){
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      let newById = Object.assign({}, state.byId, obj);
      return Object.assign({}, state, {
        byId: newById,
      });
    },
  }
}

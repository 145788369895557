import React from 'react';
import { connect } from 'react-redux';

import QuestionElementComponent from './QuestionElementComponent';

class QuestionElementContainer extends React.Component {
  render() {
    return (
      <QuestionElementComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let elementGegevens = {};
  let questionIds = state.questionElement.elementIdToQuestionElementId
    [ownProps.elementId];

  if(questionIds !== undefined){
      Object.keys(questionIds).forEach(key => {
      elementGegevens[key] = state.questionElement.byId[questionIds[key]]
    });
  }

  return {
    elementGegevens: elementGegevens,
    questions: state.question.byId,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionElementContainer);

import React from 'react';

import SideBarComponent from './SideBarComponent';
import { connect } from 'react-redux';

class SideBarContainer extends React.Component {
  render() {
    return (
      <SideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let hoofdstuk = state.hoofdstuk.byId[ownProps.match.params.hoofdstukId];
  return {
    hoofdstuk: hoofdstuk,
    questions: state.question,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer);

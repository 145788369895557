import { getFetch } from '../utils/fetches';

export const PakketApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/rest/";

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */

  apiFuncs.getPakket = function(callback){
    return getFetch(apiUrl + "Pakket", callback);
  }
  return apiFuncs;
}());

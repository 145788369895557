import React from 'react';
import { connect } from 'react-redux';

import TemplateDetailComponent from './TemplateDetailComponent';

class TemplateDetailContainer extends React.Component {
  render() {
    return (
      <TemplateDetailComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    template: state.template.byId[ownProps.match.params.templateId],
    pakket: state.pakket,
    templateToUpload: state.template.templateToUpload,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeTemplate: dispatch.template.changeTemplate,
      addTemplate: dispatch.template.addTemplate,
      linkTemplateHoofdstuk: dispatch.templateHoofdstuk.linkTemplateHoofdstuk,
      addUploadPage: dispatch.template.addUploadPage,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateDetailContainer)

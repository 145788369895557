import React from 'react';

import PreviewComponent from './PreviewComponent';
import { connect } from 'react-redux';

class PreviewContainer extends React.Component {
  render() {
    return (
      <PreviewComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let templateId = ownProps.match.params.templateId;
  let hsIds = state.templateHoofdstuk.templateToHoofdstuk[templateId];
  let hoofdstukken = {};
  if(hsIds !== undefined){
    hsIds.forEach(hsId => {
      let hsInfo = state.templateHoofdstuk.byId[templateId + "-" + hsId];
      hoofdstukken[hsInfo.order] = hsInfo
    });
  }
  return {
    hoofdstukLinks: hoofdstukken,
    hoofdstukken: state.hoofdstuk.byId,
    hoofdstukElementen: state.hoofdstukElement,
    textElements: state.textElement.byId,
    backOfficeElement: state.backOfficeQuestionElement,
    backOfficeQuestions: state.backOfficeQuestion,
    questions: state.question,
    questionElement: state.questionElement,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewContainer);

import React from 'react';
import { connect } from 'react-redux';

import MainComponent from './MainComponent';

class MainContainer extends React.Component {
  render() {
    return (
      <MainComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);

import { getFetch } from '../utils/fetches';

export const TwoColumnApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  /*
   *  Functie om adhv een state uit te vogelen wat er geupdate moet worden
   */
   apiFuncs.getAllTwoColumns = function(callback){
     return getFetch(apiUrl + "TwoColumn", callback);
   }

   apiFuncs.getTwoColumn = function(twoColumnId, callback) {
      return getFetch(apiUrl + "TwoColumn?id=" + twoColumnId,
        callback);
    }

    apiFuncs.getTwoColumnRows = function(twoColumnId, callback){
      return getFetch(apiUrl + "TwoColumn?getRows=true&id=" +
        twoColumnId, callback);
    }

    // Expected data:
    // {
    //   name: str,
    //   leftTitle: str,
    //   rightTitle: str,
    //   nrRows: int,
    // }
    apiFuncs.createTwoColumn = function(data, callback){
      return getFetch(apiUrl + "TwoColumn?createNewTwoColumn=true", callback, {
        method: "POST",
        body: JSON.stringify(data)
      });
    }

    // Expected data:
    // {
    //   twoColumnId: int,
    //   columnOrder: int,
    //   text: str,
    // }
    apiFuncs.createTwoColumnRow = function(data, callback){
      return getFetch(apiUrl + "TwoColumn?createNewTwoColumnRow=true", callback, {
        method: "POST",
        body: JSON.stringify(data)
      });
    }

    // Expected data:
    // {
    //   id: int,
    //   nrRows: int,
    // }
    apiFuncs.setNumberOfRows = function(data, callback){
      return getFetch(apiUrl + "TwoColumn?setNumerOfRows=true", callback, {
        method: "POST",
        body: JSON.stringify(data),
      });
    };

    apiFuncs.saveTwoColumnTool = function(data, callback){
      return getFetch(apiUrl + "TwoColumn?saveTwoColumnTool=true", callback, {
        method: "POST",
        body: JSON.stringify(data),
      });
    };

    apiFuncs.addTwoColumnToElement = function(data, callback){
      return getFetch(apiUrl + "TwoColumn?addTwoColumnToElement=true", callback, {
        method: "POST",
        body: JSON.stringify(data),
      });
    };

    // Expected data:
    // {
    //   id: int,
    // }
    apiFuncs.deleteTwoColumnRow = function(data, callback){
      return getFetch(apiUrl + "TwoColumn?createNewTwoColumnRow=true", callback, {
        method: "POST",
        body: JSON.stringify(data)
      });
    }

  return apiFuncs;
}());

import React from 'react';

import { Link } from 'react-router-dom';
import { TwoColumnApiHelper } from '../../../../apiHandlers/TwoColumnApiHelper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class TwoColumnToolSideBarComponent extends React.Component {

  render() {
    return (
      <React.Fragment>
        <div>
          <button
            onClick={(e) => {
              TwoColumnApiHelper.createTwoColumn({
                name: "Nieuwe tabel",
                leftTitle: "Linker titel",
                rightTitle: "Rechter titel",
                nrRows: 0
              }, (data) => {
                this.props.actions.addTwoColumn({
                  id: data.id,
                  name: "Nieuwe tabel",
                  leftTitle: "Linker titel",
                  rightTitle: "Rechter titel",
                  nrRows: 0
                })
                this.props.history.push(process.env.PUBLIC_URL + "/TwoColumnTool/" +
                this.props.match.params.hoofdstukId + "/"
                + this.props.match.params.elementId + "/"
                + data.id)
              })
            }}
          >
          <label>
            <FontAwesomeIcon icon="plus-circle" className="new" />
              Nieuw
          </label>
          </button>
        </div>
        {Object.keys(this.props.twoColumns).map(key => {
          let twoColumn = this.props.twoColumns[key];
          return <Link
            to={process.env.PUBLIC_URL + "/TwoColumnTool/" + this.props.match.params.hoofdstukId
              + "/" + this.props.match.params.elementId + "/" + twoColumn.id}
              className='sideBarElement'
              key={twoColumn.id}
          >
            <img
              src={"/letters/2.svg"}
              alt="2"
              className="svgImg"
            />
            <span className="elementName">
              {twoColumn.name}
            </span>
          </Link>
        })}
      </React.Fragment>
    )
  }
}

import React from 'react';

import LoginComponent from './LoginComponent';
import { connect } from 'react-redux';

class LoginContainer extends React.Component {
  render() {
    return (
      <LoginComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {};
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      setLoggedIn: dispatch.user.setLoggedIn,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)

export const saveText = {
  state: {
    text: ""
  },
  reducers: {
    setText(state, payload){
      return {
        text: payload.text
      }
    }
  }
}

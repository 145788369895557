import React, { Component } from 'react';
import { init } from '@rematch/core'
import { Provider } from 'react-redux';
import * as models from './models';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faChevronCircleUp,
  faChevronCircleDown,
} from '@fortawesome/free-solid-svg-icons'

import MainContainer from './components/Main/MainContainer'


class App extends Component {
  constructor(props){
    super(props);
    this.state = init({
      models
    });
    library.add(faPlusCircle)
    library.add(faChevronCircleUp)
    library.add(faChevronCircleDown)
  }
  render() {
    return (
      <Provider store={this.state}>
        <div className="App">
          <MainContainer />
        </div>
      </Provider>
    );
  }
}

export default App;

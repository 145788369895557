import React from 'react';

import GlobalSideBarContainer from '../../Generic/SideBar/GlobalSideBarContainer';

export default class SideBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  render(){
    return (
      <React.Fragment>
        {this.props.hoofdstuk !== undefined && this.props.hoofdstuk.type === "foto" ?
          <div>
            <input
              type="search"
              value={this.state.query}
              onChange={(e) => this.setState({
                query: e.target.value
              })}
              placeholder="Zoeken"
            />
            {Object.keys(this.props.questions.byId).length > 0 ?
              Object.keys(this.props.questions.byId).map(key => {
                let question = this.props.questions.byId[key];
                if(!this.props.possibleQuestionIds.includes(key)){
                  return;
                }
                if(
                  this.state.query === ""
                  || question.vraag.includes(this.state.query)
                ){
                  if(question.soort === "5" && question.type === "0"){
                    return <div className="sideBarElement"
                      draggable
                      onDragStart={(e) => this.onDragStart(e, JSON.stringify({
                        type: "foto",
                        questionId: question.id,
                      })
                    )}>
                      <img
                        className="svgImg"
                        src={"/icons/camera.svg"}
                      />
                      <span className="elementName">
                        {question.vraag}
                      </span>
                    </div>
                  }
                }
              })
            : null}
          </div> :
          <div>
            <GlobalSideBarContainer
              type="Hoofdstuk"
              history={this.props.history}
              pakket="test"
            />
          </div>
        }
      </React.Fragment>
    )
  }
}

import React from 'react';

import LoginContainer from '../Login/LoginContainer.js';
import HomeContainer from './Home/HomeContainer.js';

export default class MainComponent extends React.Component {
  render(){
    return (
      <div>          
        {this.props.loginStatus ?
          <HomeContainer />
        : <LoginContainer />}
      </div>
    )
  }
}

import React from 'react';

import MainComponent from './MainComponent';
import { connect } from 'react-redux';

class MainContainer extends React.Component {
  render() {
    return (
      <MainComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.user.loggedIn
    //loginStatus: true
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);

function generateHeaders(){
  return new Headers({
    'Authorization': sessionStorage.getItem("jwt")
  });
}

export const getFetch = (url, callback, parameters = null) => {
  let params = Object.assign({}, {
    headers: generateHeaders()
  }, parameters);

  return fetch(url, params)
  .then(result => {
    return result.json()
  })
  .then(data => callback(data));
}

import React from 'react';
import SideBarComponent from './SideBarComponent';
import { connect } from 'react-redux';

class SideBarContainer extends React.Component {
  render() {
    return <SideBarComponent
      {...this.props}
    />;
  }
}

const mapStateToProps = (state) => {
  return {
    hoofdstukElements: state.hoofdstukElement,
    backOfficeElements: state.backOfficeQuestionElement,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer)

export const pakket = {
  state: {
    byId: {},
    allIds: [],
    pakketToForm: {},
  },
  reducers: {
    addPakket: function(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      let newById = Object.assign({}, state.byId, obj);
      let allIds = [...state.allIds, payload.id];
      return {
        byId: newById,
        allIds: allIds,
        pakketToForm: state.pakketToForm,
      }
    },
    // {
    //   pakketId: int,
    //   formId: int,
    // }
    addFormToPakket: function(state, payload){
      let obj = {};
      if(Object.keys(state.pakketToForm).includes(payload.pakketId)){
        if(!state.pakketToForm[payload.pakketId].includes(payload.formId)){
          obj[payload.pakketId] = [...state.pakketToForm[payload.pakketId],
            payload.formId
          ];
        }
      } else {
        obj[payload.pakketId] = [payload.formId];
      }
      let test = Object.assign({}, state.pakketToForm, obj);
      return {
        byId: state.byId,
        allIds: state.allIds,
        pakketToForm: test,
      };
    }
  }
}

import React from 'react';
import { connect } from 'react-redux';

import TwoColumnToolComponent from './TwoColumnToolComponent';

class TwoColumnToolContainer extends React.Component {
  render() {
    return (
      <TwoColumnToolComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  if(ownProps.match.params.TwoColumnId === undefined){
    return {
      main: true,
    }
  }
  return {
    twoColumnTool: state.twoColumn.byId[ownProps.match.params.TwoColumnId],
    rows: state.twoColumn.rows[ownProps.match.params.TwoColumnId],
    main: false,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      editTwoColumn: dispatch.twoColumn.editTwoColumn,
      editTwoColumnRow: dispatch.twoColumn.editTwoColumnRow,
      setNewRows: dispatch.twoColumn.setNewRows,
      editTextElement: dispatch.textElement.editTextElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoColumnToolContainer);

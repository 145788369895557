import React from 'react';
import { connect } from 'react-redux';

import BackOfficeEditComponent from './BackOfficeEditComponent';

class BackOfficeEditContainer extends React.Component {
  render(){
    return (
      <BackOfficeEditComponent
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  let orderedData = {};
  let elementId = props.match.params.elementId;
  let backOfficeId = state.hoofdstukElement.byId[elementId].elementId;
  let elementToElementId = state.backOfficeQuestionElement
    .elementIdToQuestionElementId[
      backOfficeId
    ];

  if(typeof elementToElementId !== "undefined"){
    Object.keys(elementToElementId).forEach(order => {
      orderedData[order] = state.backOfficeQuestion.byId[elementToElementId[order]];
    });
  }

  return {
    vraagElementen: orderedData,
    backOfficeElement: state.backOfficeQuestionElement.byId[
      state.hoofdstukElement.byId[elementId].elementId],
    hoofdstukElement: state.hoofdstukElement.byId[elementId],
    reloadGarantie: state.questionElement.elementIdToQuestionElementId,
    vragen: state.question,
    hoofdstuk: state.hoofdstuk.byId[props.match.params.hoofdstukId]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addQuestionElement: dispatch.backOfficeQuestion.addQuestion,
      setOrder: dispatch.backOfficeQuestionElement.setOrder,
      linkQuestion: dispatch.backOfficeQuestionElement.linkQuestionToElement,
      changeQuestionElement: dispatch.backOfficeQuestion.changeQuestionElement,
      deleteBackOfficeSubElement: dispatch.backOfficeQuestion.deleteBackOfficeSubElement,
      unlinkQuestionToElement: dispatch.backOfficeQuestionElement.unlinkQuestionToElement,
      changeQuestion: dispatch.backOfficeQuestionElement.changeBackOfficeQuestionElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeEditContainer);

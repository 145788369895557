import React from 'react';
import { connect } from 'react-redux';

import RightSideComponent from './RightSideComponent';

class RightSideContainer extends React.Component {
  render() {
    return (
      <RightSideComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  //Sorts the hoofdstukken,m only some can be used if depenbding in the selected pakket
  let pakketId = state.template.byId[ownProps.templateId].pakketId;
  let allowedHoofdstukken = {};
  Object.keys(state.hoofdstuk.byId).forEach((id) => {
    let hoofdstuk = state.hoofdstuk.byId[id];
    if(hoofdstuk.isGeneric === "1" || hoofdstuk.pakketId === pakketId){
      allowedHoofdstukken[id] = hoofdstuk;
    }
  });
  return {
    hoofdstukken: allowedHoofdstukken,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightSideContainer)

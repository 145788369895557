import React from 'react';

import SideBarComponent from './SideBarComponent';
import { connect } from 'react-redux';

class SideBarContainer extends React.Component {
  render() {
    return (
      <SideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      addTemplate: dispatch.template.addTemplate,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer);

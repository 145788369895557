import React from 'react';

import { Link } from 'react-router-dom';

export default class TextManagerSideBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
    };
  }

  render() {
    let params = this.props.match.params;
    //console.log(this.props);
    return (
      <div>
        <input
          type="search"
          value={this.state.query}
          onChange={(e) => this.setState({
            query: e.target.value
          })}
          placeholder="Zoeken"
        />
        {Object.keys(this.props.categories).map(catKey => {
          let show = false;
          if(this.state.query === ""
            || this.props.categories[catKey].name.includes(this.state.query)
          ){
            show = true
          }
          let val = <div className='sideBarElement topLevel'>
            <img
              src={"/letters/c.svg"}
              alt="c"
              className="svgImg"
            />
            <span className="elementName">
              {this.props.categories[catKey].name}
            </span>
            {this.props.catToTexts[catKey] !== undefined ?
              <React.Fragment>
                {this.props.catToTexts[catKey].map(key => {
                  let text = this.props.texts[key];
                  if(this.state.query === "" || text.naam.includes(this.state.query)
                  ){
                    show = true
                  }
                  return <div className='sideBarElement'>
                    <Link to={process.env.PUBLIC_URL + "/TextManager/" + params.hoofdstukId + "/"
                      + params.elementId + "/" + text.id}>
                        <img
                          src="/letters/t.svg"
                          alt="t"
                          className="svgImg"
                        />
                        <span className="elementName">
                          {text.naam}
                        </span>
                    </Link>
                  </div>;
                })}
              </React.Fragment> : null}
          </div>
          if(show){
            return val;
          }
        })}
      </div>
    )
  }
}

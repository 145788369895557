import React from 'react';
import { NavLink } from "react-router-dom";

require("./style.css");

export default class NavigationComponent extends React.Component {              
        render(){

//        console.log(window.location.href.indexOf("Templates"));
//            console.log(window.location.href);
            console.log(this.props.match);
            //const currentUrl = window.location.href;
      //console.log('test', window.location.href);
      //console.log((/UploadPage/.test(window.location.href) ? ' active' : 'leeg'));
      //console.log(this.parent.location.href);
      //console.log(this.props.location);
      return (
      <React.Fragment>          
          <NavLink
            to={process.env.PUBLIC_URL + "/Templates"}
            className={"navItem"}        
          >
            Templates
          </NavLink>
          <NavLink
            to={process.env.PUBLIC_URL + "/Hoofdstukken"}
             className="navItem"
          >
            Hoofdstukken
          </NavLink>
          <NavLink
            to={process.env.PUBLIC_URL + "/Gebruikers"}
            className="navItem"
          >
            Gebruikers
          </NavLink>
      </React.Fragment>
    )
  }
}

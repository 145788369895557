import React from 'react';
import { connect } from 'react-redux';

import HoofdstukDetailComponent from './HoofdstukDetailComponent';

class HoofdstukDetailContainer extends React.Component {
  render() {
    return (
      <HoofdstukDetailComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let hoofdstukElementenList = state.hoofdstukElement.hoofdstukToIds[
    ownProps.match.params.hoofdstukId
  ];

  let elementen = [];

  if(hoofdstukElementenList !== undefined && Object.keys(hoofdstukElementenList).length > 0){
    Object.keys(hoofdstukElementenList).forEach(key => {
      let id = hoofdstukElementenList[key];
      elementen.push(state.hoofdstukElement.byId[id]);
    });
  }
  let pakketNaam = 'Generiek';
  let pakketId = state.hoofdstuk.byId[ownProps.match.params.hoofdstukId].pakketId;
  // Find linked pakketGroepen
  let linkedPakketten = [];
  if(
    pakketId !== null
    && pakketId !== 0
    && pakketId !== "0"
    && state.pakket.byId !== undefined
  ){
    pakketNaam = state.pakket.byId[state.hoofdstuk.byId[ownProps
      .match.params.hoofdstukId].pakketId].naam      
    let pakketGroepNaam = state.pakketGroep.byId[state.pakket.byId[state.hoofdstuk.byId[ownProps.match.params.hoofdstukId].pakketId].id_groep].naam;
    linkedPakketten.push({
      pakket: pakketGroepNaam,
      template: pakketNaam
    });
  } else {
    Object.keys(state.templateHoofdstuk.templateToHoofdstuk).forEach(key => {
      if(state.templateHoofdstuk.templateToHoofdstuk[key]
        .includes(ownProps.match.params.hoofdstukId)){
          linkedPakketten.push({
            pakket: state.pakket.byId[state.template.byId[key].pakketId].naam,
            template: state.template.byId[key].name,
          });
      }
    })
  }
  return {
    hoofdstuk: state.hoofdstuk.byId[ownProps.match.params.hoofdstukId],
    hoofdstukElementen: elementen,
    pakketNaam: pakketNaam,
    linkedPakketten: linkedPakketten,
    triggers: state.trigger,
    fotoHoofdstukElementen: state.fotoHoofdstukElement,
    questions: state.question,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      changeHoofdstuk: dispatch.hoofdstuk.changeHoofdstuk,
      addHoofdstuk: dispatch.hoofdstuk.addHoofdstuk,
      addHoofdstukElement: dispatch.hoofdstukElement.addHoofdstukElement,
      removeFotoHoofdstukElement: dispatch.fotoHoofdstukElement.removeFotoHoofdstukElement,
      fixFotoOrders: dispatch.fotoHoofdstukElement.fixOrder,
      addFotoHoofdstukElement: dispatch.fotoHoofdstukElement.addFotoHoofdstukElement,
      changeFotoElement: dispatch.fotoHoofdstukElement.changeFotoElement,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HoofdstukDetailContainer);

export const backOfficeQuestionElement = {
  state: {
    byId: {},
    elementIdToQuestionElementId: {},
    allIds: [],
  },
  reducers: {
    // Expected payload:
    // {
    //   id: int,
    //   leftTitle: str,
    //   rightTitle: str,
    // }
    addBackOfficeElement: function(state, payload){
      let obj = {};
      obj[payload.id] = payload;
      return {
        byId: Object.assign({}, state.byId, obj),
        allIds: [...state.allIds, payload.id],
        elementIdToQuestionElementId: state.elementIdToQuestionElementId,
      }
    },

    // Expected payload:
    // {
    //   backOfficeElementId: int,
    //   order: int,
    // }
    unlinkQuestionToElement: function(state, payload){
      let boId = payload.backOfficeElementId;
      let newObj = {};
      Object.keys(state
        .elementIdToQuestionElementId[boId]).map(key => {
            if(key !== payload.order){
              newObj[key] = state.elementIdToQuestionElementId[boId][key];
            }
        });
      let obj = {};
      obj[boId] = newObj;
      return Object.assign({}, state, {
        elementIdToQuestionElementId: Object.assign({},
          state.elementIdToQuestionElementId, obj)
      });
    },

    // Expected payload:
    // {
    //   order: int,
    //   id: int,
    //   backOfficeQuestionElementId: int,
    // }
    linkQuestionToElement: function(state, payload){
      let newElementIdToQuestionElementId = state.elementIdToQuestionElementId;
      let newObj = {};
      newObj[payload.order] = payload.id;
      let relevantState = state.elementIdToQuestionElementId;
      if(Object.keys(state.elementIdToQuestionElementId)
        .includes(payload.backOfficeQuestionElementId)) {
          relevantState[payload.backOfficeQuestionElementId] = Object.assign({},
            relevantState[payload.backOfficeQuestionElementId], newObj);
      } else {
        relevantState[payload.backOfficeQuestionElementId] = newObj;
      }
      return Object.assign({}, state, {
        elementIdToQuestionElementId: relevantState
      });
    },

    // {
    //   elementId: int,
    //   order: obj
    // }
    setOrder(state, payload){
      let newOrder = {};
      payload.order.forEach(el => {
        newOrder[el.order] = el.id;
      });
      let obj = {};
      obj[payload.elementId] = newOrder;
      let elementIdToQuestionElementId = Object.assign({}, state
        .elementIdToQuestionElementId, obj);
      let ret = Object.assign({}, state, {
        elementIdToQuestionElementId: elementIdToQuestionElementId
      });
      return ret;
    },

    // Expected payload:
    // {
    //   id: int,
    //   key: val
    // }
    changeBackOfficeQuestionElement(state, payload){
      let obj = {};
      obj[payload.id] = Object.assign({}, state.byId[payload.id], payload);
      let newById = Object.assign({}, state.byId, obj);
      return Object.assign({}, state, {
        byId: newById,
      });
    },
  }
};

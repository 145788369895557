import React from 'react';
import { Link } from 'react-router-dom';
import { UploadHoofdstukApiHelper } from '../../apiHandlers/UploadHoofdstukApiHelper.js';

require("./style.css")

export default class UploadPageComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedVraag: "0",
    };
  }

  onDragStart(e, data){
    e.dataTransfer.setData("data", data);
    e.stopPropagation();
  }

  changeForm(e){
    var type = e.target.name;
    var value = e.target.value;
    if(e.target.type === "checkbox"){
      if(e.target.checked){
        value = "1";
      } else {
        value = "0";
      }
    }
    let obj = {
      id: this.state.selectedVraag,
    };
    obj[type] = value;
    this.props.actions.changeUploadElement(obj);
  }

  dropTrigger(questionId, order){
    let lastKey;
    let existingElements = this.props.uploadHoofdstukElementen
      .hoofdstukToIds[this.props.match.params.pageId];
    let newQuestion = true;
    let oldOrder = 0;
    if(existingElements !== undefined
      && Object.keys(existingElements).length > 0
    ){
      Object.keys(existingElements).forEach(key => {
        if(
          this.props.uploadHoofdstukElementen.byId[
            existingElements[key]
          ].questionId === questionId
        ){
          oldOrder = key;
          newQuestion = false;
        }
      })
    }

    if(newQuestion) {
      UploadHoofdstukApiHelper.addQuestionToPage({
        uploadPageId: this.props.match.params.pageId,
        questionId: questionId,
        order: order,
      }, (data) => {
        this.props.actions.addQuestionToPage({
          id: data.id,
          uploadPageId: this.props.match.params.pageId,
          questionId: questionId,
          showIndex: data.showIndex,
          merge: data.merge,
          order: order,
          alternativeName: data.alternativeName,
        });

        this.props.actions.fixOrder({
          id: this.props.match.params.pageId,
          order: data.order
        });
      });
    } else {
      let element = this.props.uploadHoofdstukElementen.byId[
        existingElements[oldOrder]
      ];
      UploadHoofdstukApiHelper.updateUploadQuestion({
        id: element.id,
        questionId: element.questionId,
        uploadPageId: this.props.match.params.pageId,
        alternativeName: element.alternativeName,
        showIndex: element.showIndex,
        merge: element.merge,
        order: order,
      }, (data) => {
        this.props.actions.fixOrder({
          id: this.props.match.params.pageId,
          order: data.order
        });
      });
    }
  }

  render() {
    //  console.log(this.props);
    return (
      <React.Fragment>
        <div className="title">
          Template > Upload pagina
        </div>
        <div style={{width: "30%", float: 'left'}}>
          {this.state.selectedVraag !== "0" ?
            <React.Fragment>
              <span className="leftSBTitle" style={{fontSize: "16px"}}>
                Instellingen vraag
              </span>
              <br />
               <br />
              
                <span className="leftSBTitle">
              Upload vraag:<br />
                 </span>
              {this.props.questions.byId[this.props.uploadHoofdstukElementen.byId[
                this.state.selectedVraag
              ].questionId].vraag}
              <br />
               <br />

              <span className="leftSBTitle">
                Naamgeving bestand:
              </span>
              <br />
              <input type="text"
                value={this.props.uploadHoofdstukElementen.byId[
                  this.state.selectedVraag
                ].alternativeName}
                onChange={(e) => this.changeForm(e)}
                name="alternativeName"
              />
              <br />
               <br />

              <span className="leftSBTitle">
                Inhoudsopgave:
              </span>
              <input
                onChange={(e) => this.changeForm(e)}
                name="showIndex"
                type="checkbox"
                checked={this.props.uploadHoofdstukElementen.byId[
                  this.state.selectedVraag
                ].showIndex == '1' ? true
                      : false} 
              />
              <br />
              <span className="leftSBTitle">
                Samenvoegen:
              </span>
              <input
                onChange={(e) => this.changeForm(e)}
                name="merge"
                type="checkbox"
                checked={this.props.uploadHoofdstukElementen.byId[
                  this.state.selectedVraag
                ].merge == '1' ? true
                      : false} 
              />
              <br />

              <button onClick={() => {
           
//               UploadHoofdstukApiHelper.updateUploadQuestion(
//                hoofdstukElementId: element.id,
//                  questionId: element.questionId,
//                  text: text,
//                  order: element.order
//              , () => {});
                  
                  
                  let merge = 0;
                if(document.querySelector("input[name=merge]").checked) {
                  merge = 1;
              }
              
              let showIndex = 0;
               if(document.querySelector("input[name=showIndex]").checked) {
                  showIndex = 1;
              }          
              
      
         UploadHoofdstukApiHelper.updateUploadQuestion({
                    id: this.props.hoofdstukElementen[0].id,
                    uploadPageId: parseInt(this.props.match.params.pageId),
                    questionId: this.props.hoofdstukElementen[0].questionId,
//                    order: document.querySelector("input[name=name]").value,
                    order: 0,
                    alternativeName: document.querySelector("input[name=alternativeName]").value,
                    showIndex: showIndex,
                    merge: merge
                }, () => alert('Bedankt, uw template is opgeslagen')
                    )
            
//                 UploadHoofdstukApiHelper.updateUploadQuestion({
//                   hoofdstukElementId: element.id,
//                   questionId: element.questionId,
//                   text: text,
//                   order: element.order,
//                 }, (data) => {
//                   this.setState({
//                     selectedVraag: "0"
//                   });
//                   this.props.actions.changeFotoElement({
//                     id: element.id,
//                     text: text
//                   })
//                 });
              }}>
                Sla op
              </button>
            </React.Fragment> :
            <React.Fragment>
              Details
            </React.Fragment>
          }
        </div>
        <div style={{
          width: "70%",
          height: "80%",
          display: "inline-block",
          backgroundColor: "#d3d3d3",
          float: "left",
          verticalAlign: "top",
        }}>
          <div
            key={"droppableStatic"}
            className="staticDropArea"
            onDrop={(e) => {
              let dropData = JSON.parse(e.dataTransfer.getData("data"));
              this.dropTrigger(dropData.questionId, "1")
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragLeave={(e) => {
              document.querySelectorAll(".dropArea").forEach(el => {
                el.style.display = "none";
              });
            }}
          ></div>
          {Object.keys(this.props.uploadHoofdstukElementen.hoofdstukToIds)
            .includes(this.props.match.params.pageId) ?
            Object.keys(this.props.uploadHoofdstukElementen.hoofdstukToIds[
              this.props.match.params.pageId
            ]).map(order => {
              let uploadHoofdstukElementen = this.props.uploadHoofdstukElementen;
              let question = uploadHoofdstukElementen.byId[
                uploadHoofdstukElementen.hoofdstukToIds[
                  this.props.match.params.pageId
              ][order]];
              return <React.Fragment>
                <div
                  className="sideBarElement"
                  draggable
                  onDragStart={(e) => this.onDragStart(e, JSON.stringify({
                    type: "foto",
                    questionId: question.questionId,
                  }))}
                  onDrop={(e) => {
                    let dropData = JSON.parse(e.dataTransfer.getData("data"));
                    this.dropTrigger(dropData.questionId,
                    parseInt(order, 10) + 1);
                    document.querySelectorAll(".dropArea").forEach(el => {
                      el.style.display = "none";
                    });
                  }}
                  onDragOver={(e) => {
                    let target = e.target;
                    while(target.getAttribute("class") !== 'sideBarElement'){
                      target = target.parentNode;
                    }
                    target.nextSibling.style.display = "block";
                    e.preventDefault();
                  }}
                  onDragLeave={(e) => {
                    document.querySelectorAll(".dropArea").forEach(el => {
                      el.style.display = "none";
                    });
                  }}
                  onClick={() => {
                    this.setState({
                      selectedVraag: question.id,
                    });
                  }}
                >
                  <img
                    src={"/letters/v.svg"}
                    className="svgImg"
                    alt="V"
                  />
                  <span className="elementName" style={{
                    width: '95%',
                  }}>
                    {this.props.questions.byId[question.questionId].vraag}
                    <span className="deleteButton" style={{
                      float: 'right'
                    }}>
                      <img
                        src={"/letters/x.svg"}
                        alt="x"
                        className="svgImg"
                        onClick={(e) => {
                          e.stopPropagation();
                          if(this.state.selectedVraag === question.id){
                            this.setState({
                              selectedVraag: "0"
                            });
                          }
                          UploadHoofdstukApiHelper.deleteUploadQuestion({
                            id: question.id,
                          }, (data) => {
                            this.props.actions.removeUploadHoofdstukElement({
                              uploadPageId: question.id,
                            });
                            this.props.actions.fixOrder({
                              id: this.props.match.params.pageId,
                              order: data.order
                            });
                          })
                        }}
                      />
                    </span>
                  </span>
                </div>
                <div class="dropArea" style={{display: 'none'}}></div>
              </React.Fragment>
            })
            : null}
        </div>
        <div style={{clear: "both"}} className="buttonBar">
          <button
            onClick={() => {
              this.props.history.push(process.env.PUBLIC_URL + "/Templates/");
            }}
          >
            Annuleer
          </button>
          <button
            onClick={() => {
              //console.log("save")
              alert('Bedankt, uw upload pagina is opgeslagen')
            }}
          >
            Bewaar hoofdstuk
          </button>
        </div>
      </React.Fragment>
    )
  }
}

import React from 'react';
import { Route } from 'react-router-dom';

import { default as HomeContainer }
  from '../components/Main/Home/HomeContainer';
import { default as  TemplatesMainContainer }
  from '../components/Templates/Main/MainContainer';
import TemplateDetailContainer
  from '../components/Templates/TemplateDetail/TemplateDetailContainer';
import { default as GebruikersMainContainer }
  from '../components/Gebruikers/Main/MainContainer';
import GebruikerDetailsContainer
  from '../components/Gebruikers/GebruikerDetails/GebruikerDetailsContainer';
import { default as HoofdstukkenMainContainer }
  from '../components/Hoofdstukken/Main/MainContainer';
import HoofdstukDetailContainer
  from '../components/Hoofdstukken/HoofdstukDetail/HoofdstukDetailContainer';
import {default as HoofdstukEditContainer }
  from '../components/Hoofdstukken/HoofdstukEdit/HoofdstukEditContainer';
import {default as VragenElementEditContainer }
  from '../components/Hoofdstukken/VragenElementEdit/VragenElementEditContainer';
import {default as BackOfficeEditContainer }
  from '../components/Hoofdstukken/BackOfficeElementEdit/BackOfficeEditContainer';
import {default as TwoColumnToolContainer }
  from '../components/Hoofdstukken/TwoColumnTool/TwoColumnToolContainer';
import {default as TextManagerContainer }
  from '../components/Hoofdstukken/TextManager/TextManagerContainer';
import {default as TextManagerAddContainer }
  from '../components/Hoofdstukken/TextManagerAdd/TextManagerAddContainer';
import { default as  TemplatesPreviewContainer }
  from '../components/Templates/Preview/PreviewContainer';
import { default as  UploadPageContainer }
  from '../components/UploadPage/UploadPageContainer';

import { default as TemplatesSideBarContainer }
  from '../components/Templates/SideBar/SideBarContainer';
import { default as GebruikersSideBarContainer }
  from '../components/Gebruikers/SideBar/SideBarContainer';
import { default as HoofdstukkenSideBarContainer}
  from '../components/Hoofdstukken/SideBar/SideBarContainer';
import { default as FormulierSideBarContainer}
  from '../components/Hoofdstukken/HoofdstukEdit/SideBar/FormulierSideBarContainer';
import {default as TwoColumnToolSideBarContainer }
  from '../components/Hoofdstukken/TwoColumnTool/SideBar/TwoColumnToolSideBarContainer';
import {default as TextManagerSideBarContainer }
  from '../components/Hoofdstukken/TextManager/SideBar/TextManagerSideBarContainer';
import {default as TextManagerAddSideBarContainer }
  from '../components/Hoofdstukken/TextManagerAdd/SideBar/TextManagerAddSideBarContainer';
import { default as BackOfficeElementEditSideBarContainer }
  from '../components/Hoofdstukken/BackOfficeElementEdit/SideBar/SideBarContainer';
import { default as  UploadPageSidebarContainer }
  from '../components/UploadPage/SideBar/SideBarContainer';

export default {
  left: [
    <Route
      key='Home'
      path={process.env.PUBLIC_URL + "/"}
      exact
      component={HomeContainer}
    />,
    <Route
      exact
      key="Templates"
      path={process.env.PUBLIC_URL + "/Templates"}
      component={TemplatesMainContainer}
    />,
    <Route
      exact
      key="TemplatesDetail"
      path={process.env.PUBLIC_URL + "/Templates/:templateId"}
      component={TemplateDetailContainer}
    />,
    <Route
      exact
      key="TemplatePreview"
      path={process.env.PUBLIC_URL + "/Templates/:templateId/preview/"}
      component={TemplatesPreviewContainer}
    />,
    <Route
      exact
      key="TemplatesDetailAction"
      path={process.env.PUBLIC_URL + "/Templates/:templateId/:action"}
      component={TemplateDetailContainer}
    />,
    <Route
      exact
      key="Gebruikers"
      path={process.env.PUBLIC_URL + "/Gebruikers"}
      component={GebruikersMainContainer}
    />,
    <Route
      exact
      key="GebruikersDetails"
      path={process.env.PUBLIC_URL + "/Gebruikers/:gebruikersId"}
      component={GebruikerDetailsContainer}
    />,
    <Route
      exact
      key="Hoofdstukken"
      path={process.env.PUBLIC_URL + "/Hoofdstukken"}
      component={HoofdstukkenMainContainer}
    />,
    <Route
      exact
      key="HoofdstukDetails"
      path={process.env.PUBLIC_URL + "/Hoofdstukken/:hoofdstukId"}
      component={HoofdstukDetailContainer}
    />,
    <Route
      exact
      key="HoofdstukDetailsAction"
      path={process.env.PUBLIC_URL + "/Hoofdstukken/:hoofdstukId/edit"}
      component={HoofdstukEditContainer}
    />,
    <Route
      exact
      key="HoofdstukElementEdit"
      path={process.env.PUBLIC_URL + "/Hoofdstukken/:hoofdstukId/edit/:elementId"}
      component={VragenElementEditContainer}
    />,
    <Route
      exact
      key="HoofdstukElementEdit"
      path={process.env.PUBLIC_URL + "/Hoofdstukken/:hoofdstukId/editBackoffice/:elementId"}
      component={BackOfficeEditContainer}
    />,
    <Route
      exact
      key="TwoColumnTool"
      path={process.env.PUBLIC_URL + "/TwoColumnTool/:hoofdstukId/:elementId/:TwoColumnId?"}
      component={TwoColumnToolContainer}
    />,
    <Route
      exact
      key="TextManager"
      path={process.env.PUBLIC_URL + "/TextManager/:hoofdstukId/:elementId/add/"}
      component={TextManagerAddContainer}
    />,
    <Route
      exact
      key="TextManager"
      path={process.env.PUBLIC_URL + "/TextManager/:hoofdstukId/:elementId/:textId?"}
      component={TextManagerContainer}
    />,
    <Route
      exact
      key="UploadPage"
      path={process.env.PUBLIC_URL + "/UploadPage/:pageId"}
      component={UploadPageContainer}
    />,
  ],
  right: [
    <Route
      key="Templates"
      path={process.env.PUBLIC_URL + "/Templates"}
      component={TemplatesSideBarContainer}
    />,
    <Route
      key="Gebruikers"
      path={process.env.PUBLIC_URL + "/Gebruikers"}
      component={GebruikersSideBarContainer}
    />,
    <Route
      exact
      key="VragenEdit"
      path={process.env.PUBLIC_URL + "/Hoofdstukken/:hoofdstukId/edit/:elementId"}
      component={FormulierSideBarContainer}
    />,
    <Route
      exact
      key="VragenEdit"
      path={process.env.PUBLIC_URL + "Hoofdstukken/:hoofdstukId/editBackoffice/:elementId"}
      component={BackOfficeElementEditSideBarContainer}
    />,
    <Route
      key="Hoofdstukken"
      path={process.env.PUBLIC_URL + "/(Hoofdstukken|Hoofdstuk)/:hoofdstukId"}
      component={HoofdstukkenSideBarContainer}
    />,
    <Route
      key="Hoofdstukken"
      path={process.env.PUBLIC_URL + "/(Hoofdstukken|Hoofdstuk)/"}
      component={HoofdstukkenSideBarContainer}
    />,
    <Route
      exact
      key="TwoColumnTool"
      path={process.env.PUBLIC_URL + "/TwoColumnTool/:hoofdstukId/:elementId/:TwoColumnId?"}
      component={TwoColumnToolSideBarContainer}
    />,
    <Route
      exact
      key="TextManager"
      path={process.env.PUBLIC_URL + "/TextManager/:hoofdstukId/:elementId/add/"}
      component={TextManagerAddSideBarContainer}
    />,
    <Route
      exact
      key="TextManager"
      path={process.env.PUBLIC_URL + "/TextManager/:hoofdstukId/:elementId/:textId?"}
      component={TextManagerSideBarContainer}
    />,
    <Route
      exact
      key="UploadPage"
      path={process.env.PUBLIC_URL + "/UploadPage/:pageId"}
      component={UploadPageSidebarContainer}
    />,
  ]
}

import React from 'react';
// import { Redirect } from 'react-router-dom';
import { AuthenticateApiHelper } from '../../apiHandlers/AuthenticateApiHelper.js'

export default class LoginComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        username : "",
        password: "",
        loginSuccesfull: false,
      };
    }

    preformLogin(){
      if(this.validateForm()){
        AuthenticateApiHelper.authenticate(this.state.username,
          this.state.password, (data) => {
            if(data === undefined){
              return false;
            }
            sessionStorage.setItem("jwt", JSON.parse(data).token);
            this.props.actions.setLoggedIn({
              loggedIn: true,
              userId: JSON.parse(data).id,
            });
          }
        )
      }
    }

    validateForm(){
      return this.state.username.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }

    handleSubmit = event => {
      event.preventDefault();
    }

    render() {
      return (
        <div className="login">
          <form onSubmit={this.handleSubmit}>
            <div className="loginField">
              <label htmlFor="username">
                Username
              </label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={this.handleChange}
                value={this.state.email}
              />
            </div>
            <div className="loginField">
              <label htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                onChange={this.handleChange}
                value={this.state.password}
              />
            </div>
            <button
              className="submitForm"
              disabled={!this.validateForm()}
              onClick={() => this.preformLogin()}
            >
              Login
            </button>
          </form>
        </div>
      )
    }
}

import React from 'react';
import { connect } from 'react-redux';

import TextManagerSideBarComponent from './TextManagerSideBarComponent';

class TextManagerSideBarContainer extends React.Component {
  render() {
    return (
      <TextManagerSideBarComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let hoofdstuk = state.hoofdstuk.byId[ownProps.match.params.hoofdstukId];
  let categories = {};
  if(hoofdstuk.isGeneric === "1" || hoofdstuk.pakketId === null){
    categories = state.textManager.categories
  } else {
    //Find appliccable categories
    Object.keys(state.textManager.categories).forEach(key => {
      let cat = state.textManager.categories[key];
      //if(cat.pakketGroepId === hoofdstuk.pakketId){
        categories[cat.id] = cat;
      //}
    })
  }
  return {
    categories: categories,
    texts: state.textManager.texts,
    catToTexts: state.textManager.catToTexts,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextManagerSideBarContainer);

import React from 'react';

import RightSideContainer from './RightSide/RightSideContainer';
import LeftSideContainer from './LeftSide/LeftSideContainer';
import { TemplateApiHelper } from '../../../apiHandlers/TemplateApiHelper';
import { PdfApiHelper } from '../../../apiHandlers/PdfApiHelper';
import { Link } from 'react-router-dom';
import { UploadHoofdstukApiHelper } from '../../../apiHandlers/UploadHoofdstukApiHelper';

require("./style.css");

export default class TemplateDetailComponent extends React.Component {
  changeForm(e){
    var type = e.target.name;
    var value = e.target.value;

    if(e.target.type === "checkbox"){
      if(e.target.checked){
        value = "1";
      } else {
        value = "0";
      }
    }
    let obj = {
      id: this.props.template.id,
    };
    obj[type] = value;
    this.props.actions.changeTemplate(obj);
  }

  render(){
    return (
      <React.Fragment>
        <div className="title">
          Templates
        </div>
        <table className="settingsTable">
          <tbody>
            <tr>
              <td>
                Template naam
              </td>
              <td>
                <input
                  type="text"
                  name="name"
                  value={this.props.template.name}
                  onChange={(e) => this.changeForm(e)}
                />
              </td>
            </tr>
            <tr>
              <td>
                Kies pakket
              </td>
              <td>
                <select
                  onChange={(e) => this.changeForm(e)}
                  value={this.props.template.pakketId}
                  name="pakketId"
                >
                  <option value="0">
                    Maak een keuze
                  </option>
                    {this.props.pakket.allIds.map(id => {
                      return (
                        <option key={id} value={id}>
                          {this.props.pakket.byId[id].naam}
                        </option>
                      )
                    })}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                Upload pagina:
              </td>
              <td>
                <input
                  type="checkbox"
                  name="showUploadPage"
                  onChange={(e) => this.changeForm(e)}
                  checked={this.props.template.showUploadPage === "1" ?
                    "checked" : false}
                />
                {Object.keys(this.props.templateToUpload).includes(
                  this.props.template.id
                ) ? <Link
                    to={process.env.PUBLIC_URL + "/UploadPage/" + this.props.templateToUpload[
                      this.props.template.id
                    ]}
                  >
                    Ga naar upload pagina
                  </Link>
                : <button
                    onClick={() => {
                      UploadHoofdstukApiHelper.createNewUploadPage({
                        templateId: this.props.template.id,
                      }, (data => {
                        this.props.actions.addUploadPage({
                          templateId: this.props.template.id,
                          uploadPageId: data.id,
                        });
                        this.props.history.push(process.env.PUBLIC_URL + "/UploadPage/" + data.id);
                      }))
                    }}
                  >
                  Maak upload pagina
                </button>}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="TemplateHoofdstukken">
          <div className="splitView">
            <div className="splitViewTitle">
              Samenstelling template:
            </div>
            <LeftSideContainer
              templateId={this.props.match.params.templateId}
            />
          </div>
          <div className="splitView">
            <div className="splitViewTitle">
              Voeg hoofdstukken toe aan deze template:
            </div>
            <RightSideContainer
              templateId={this.props.match.params.templateId}
            />
          </div>
          <div style={{clear: 'both'}}></div>
          <div className="buttonBar">
            <button
              className="duplicateBtn"
              onClick={(e) => {
                TemplateApiHelper.duplicateTemplate ({
                  templateId: this.props.match.params.templateId,
                }, (data) => {
                  console.log("response: ", data)
                  this.props.actions.addTemplate(data.template);
                  if(data.regels.length > 0){
                    data.regels.forEach(regel => [
                      this.props.actions.linkTemplateHoofdstuk(regel)
                    ])
                  }
                })
              }}
            >
              Dupliceer
            </button>
            <Link
              to={process.env.PUBLIC_URL + "/Templates/" + this.props.match.params.templateId
                + "/preview"}
            >
              <button>
                Preview
              </button>
            </Link>

            <button
              className="pdfBtn"
              onClick={(e) => {
                PdfApiHelper.getPdf({
                  id: this.props.match.params.templateId,
                }, (data) => {                 
                   var element = document.createElement('a');
                    element.setAttribute('href', process.env.REACT_APP_API_URL + '/files/pdfs/example_001.pdf');

                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);
                  
                  setTimeout(() => {
                   const response = {
                     file: process.env.REACT_APP_API_URL + '/files/pdfs/example_001.pdf',
                   };
                   // server sent the url to the file!
                   // now, let's download:
                   window.open(response.file);
                   // you could also do:
                   // window.location.href = response.file;
                 }, 100);
                 
                })
              }}
            >
              Genereer PDF
            </button>
            <button
              className="saveBtn"
              onClick={(e) => {
                let showUploadPage = 0;
                // if(document.querySelector("select[name=pakket]"))
                if(document.querySelector("input[name=showUploadPage]").checked){
                  showUploadPage = 1;
                }
                TemplateApiHelper.updateTemplate({
                    templateId: this.props.match.params.templateId,
                    name: document.querySelector("input[name=name]").value,
                    pakketId: document.querySelector("select[name=pakketId]").value,
                    showUploadPage: showUploadPage
                }, () => alert('Bedankt, uw template is opgeslagen') //console.log("callback called :D")
              )
              }}
            >
              Sla op
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

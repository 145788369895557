import { getFetch } from '../utils/fetches';

export const TextManagerApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

   apiFuncs.getAllTextCategories = function(callback){
     return getFetch(apiUrl + "TextManager?categorieen=true", callback);
   }

    apiFuncs.getAllTexts = function(callback){
      return getFetch(apiUrl + "TextManager", callback);
    }

    // Expected data:
    // {
    //   pakketGroepId: int,
    //   name: str,
    // }
    apiFuncs.createTwoColumn = function(data, callback){
      return getFetch(apiUrl + "TextManager?createCat=true", callback, {
        method: "POST",
        body: JSON.stringify(data)
      });
    }

    // Expected data:
    // {
    //   catId: int,
    //   text: str,
    //   naam: str,
    // }
    apiFuncs.createText = function(data, callback){
      return getFetch(apiUrl + "TextManager?createText=true", callback, {
        method: "POST",
        body: JSON.stringify(data)
      });
    }

    // Expected data:
    // {
    //   id: int,
    //   text: str,
    // }
    apiFuncs.createTwoColumn = function(data, callback){
      return getFetch(apiUrl + "TextManager?updateText=true", callback, {
        method: "PUT",
        body: JSON.stringify(data)
      });
    }
    // Expected data:
    // {
    //   pakketGroepId: int,
    //   name: str,
    // }
    apiFuncs.createCategory = function(data, callback){
      return getFetch(apiUrl + "TextManager?createCat=true", callback, {
        method: "POST",
        body: JSON.stringify(data)
      });
    }


  return apiFuncs;
}());

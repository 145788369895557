export const FotoHoofdstukApiHelper = (function() {
  let apiFuncs = {};
  const apiUrl = process.env.REACT_APP_API_URL + "/restRmaker/";

  apiFuncs.generateHeaders = function(){
    return new Headers({
      'Authorization': sessionStorage.getItem("jwt"),
      'content-type': 'application/json',
    });
  }
  //
  // Expected data:
  // {
  //   text: str,
  //   questionId: int,
  //   hoofdstukId: int,
  // }
  apiFuncs.addFotoToHoofdstuk = function(data, callback){
    return fetch(apiUrl + "FotoHoofdstuk?addFotoToHoofdstuk=true", {
      headers: this.generateHeaders(),
      method: 'POST',
      body: JSON.stringify({
        text: data.text,
        questionId: data.questionId,
        hoofdstukId: data.hoofdstukId,
      })
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // {
  //   hoofdstukElementId: int
  // }
  apiFuncs.deleteHoofdstukElement = function(data, callback){
    return fetch(apiUrl + "FotoHoofdstuk?deleteHoofdstukElement=true", {
      headers: this.generateHeaders(),
      method: 'DELETE',
      body: JSON.stringify({
        hoofdstukElementId: data.hoofdstukElementId
      })
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  // Expected data
  // {
    // hoofdstukElementId: int,
    // questionId: int,
    // text: str,
    // order: int,
  // }
  apiFuncs.changeHoofdstukElement = function(data, callback){
    return fetch(apiUrl + "FotoHoofdstuk?changeHoofdstukElement=true", {
      headers: this.generateHeaders(),
      method: 'PUT',
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => callback(data));
  }

  return apiFuncs;
}());

import React from 'react';

import QuestionElementContainer from './Elementen/QuestionElement/QuestionElementContainer';
import TextElementContainer from './Elementen/TextElement/TextElementContainer';
import BackOfficeElementContainer from './Elementen/BackOfficeElement/BackOfficeElementContainer';
import ToolbarContainer from './Toolbar/ToolbarContainer.js';
import { HoofdstukElementApiHelper } from '../../../apiHandlers/HoofdstukElementApiHelper';
import { Link } from 'react-router-dom';

require("./style.css");

export default class HoofdstukEditComponent extends React.Component {
  render() {
    let elKeys = Object.keys(this.props.elements);

    return (
      <div className="hoofdstukElementOverzicht">
        <div className="title">
          Hoofdstuk > {this.props.hoofdstuk.title}
        </div>
        <ToolbarContainer
          keys="toolbarLast"
          order="0"
          hoofdstukId={this.props.match.params.hoofdstukId}
        />
          {elKeys.length > 0 ? elKeys.map(key => {
            return <React.Fragment key={"fragment" + key}>
              <div key={"containingElement" + key}>

                {this.props.elements[key].type === "vragen" ?
                  <Link
                    to={process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.match.params.hoofdstukId +
                      "/edit/" + this.props.elements[key].id}
                    key={process.env.PUBLIC_URL + "Link" + key}
                  >
                    <QuestionElementContainer
                      key={key}
                      elementId={this.props.elements[key].id}
                    /> <br />
                  </Link> :
                this.props.elements[key].type === "text" ?
                  <TextElementContainer
                    key={key}
                    elementId={this.props.elements[key].elementId}
                    history={this.props.history}
                    match={this.props.match}
                  />
                : this.props.elements[key].type === "backoffice" ?
                <div>
                  BackOffice vragen:
                  <Link
                    to={process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.match.params.hoofdstukId +
                      "/editBackoffice/" + this.props.elements[key].id}
                    key={"Link" + key}
                  >
                    <BackOfficeElementContainer
                      key={key}
                      elementId={this.props.elements[key].id}
                    />
                  </Link>
                </div>
                : null}
            </div>
            <ToolbarContainer
              key={"toolbar" + this.props.elements[key].order}
              order={this.props.elements[key].order}
              hoofdstukId={this.props.match.params.hoofdstukId}
            />
          </React.Fragment>
        }) : null}
        <div className="buttonBar">
          <button
            onClick={() => {
              //Doorloop alle text elementen en sla ze op
              Object.keys(this.props.textElement).forEach(id => {
                HoofdstukElementApiHelper.editTextElement(this.props
                  .textElement[id], (data) => {});
              })
              alert('Bedankt, uw hoofdstuk is opgeslagen')
            }}
            value="Sla op"
          >Sla op</button>
          <Link
            to={process.env.PUBLIC_URL + "/Hoofdstukken/" + this.props.match.params.hoofdstukId}
            key={"LinkEind"}
          >
          <button
            value="Terug"
          >Terug</button>
          </Link>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';

import TextElementComponent from './TextElementComponent';

class TextElementContainer extends React.Component {
  render() {
    return (
      <TextElementComponent
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    textElement: state.textElement.byId[ownProps.elementId]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      editTextElement: dispatch.textElement.editTextElement,
      saveText: dispatch.saveText.setText,
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextElementContainer);
